import { table } from "console";
import React from "react";
import { useSelector } from "react-redux";
// import "../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "./table.css";
function Table() {
  const tableData: any = useSelector((state: any) => state.table.data);
  // console.log("tableData", tableData);
  // var newTable = [...tableData].reverse()
  // newTable.splice(0)

  return (
   <div className="container mt-4">
        <table className="table w-100">
      <thead className="thead-dark">
        <tr>
          <th >Txn</th>
          <th >Wallet Address</th>
          <th >Time</th>
          <th >Amount Paid</th>
        </tr>
      </thead>
      <tbody>
        {tableData.length ? (
          tableData.map((row: any) => (
            <tr key={row.currentDateTime}>
              <td title={row.txn}>{row.txn.slice(0,4)+".."+row.txn.slice(-5)}</td>
              <td title={row.Address}>{row.Address.slice(0,4)+".."+row.Address.slice(-5)}</td>

              <td>{row.currentDateTime}</td>
              <td>{row.AmountPayed}</td>
            </tr>
          ))
        ) : (
          <tr>
            <td>No transaction found</td>
          </tr>
        )}
      </tbody>
    </table>
   </div>
  );
}

export default Table;
