import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useWeb3React } from "@web3-react/core";
import {
  SelectedContractInterface,
  ContractUriType,
} from "../../containers/deployPage";
import axios from "axios";
import { toast } from "react-toastify";
import { API_URLS } from "../../config";
import Web3 from "web3";
import { switchNetwork } from "../../web3/functions";

interface UpdateContractUriFormProps {
  urlInputName: string;
  contractDetails: any;
  type: ContractUriType;
  onClose?: () => void;
  successHandler?: () => void;
}

const UpdateContractUriForm = ({
  urlInputName,
  contractDetails,
  type,
  onClose,
  successHandler,
}: UpdateContractUriFormProps) => {
  const { chainId, account, library } = useWeb3React();

  const [txnHash, setTxnHash] = useState<string | null>(null);
  const [btnDisabled, setBtnDisbaled] = useState<boolean>(false);
  const [showLoader, setShowLoader] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    trigger,
    setValue,
  } = useForm();

  useEffect(() => {
    if (contractDetails && contractDetails.id) {
      setValue(
        urlInputName,
        contractDetails && contractDetails[urlInputName]
          ? contractDetails[urlInputName]
          : ""
      );
    }
  }, [contractDetails?.id]);

  const onSubmit = async (data: any) => {
    try {
      setBtnDisbaled(true);
      let formParams = { ...data, contractId: contractDetails.id };
      if (!contractDetails?.id || !contractDetails?.abi) {
        toast.error("Invalid contract details");
      }
      let abi = contractDetails.abi;
      abi = abi.replace("'", "");
      abi = abi.slice(0, -1);

      const web3 = new Web3(library.provider);
      let currentChainId = await web3.eth.getChainId();
      let selectedChain = contractDetails.chain_id;
      if (currentChainId !== selectedChain) {
        switchNetwork(String(selectedChain), library).then(() =>
          onSubmit(data)
        );
        return;
      }

      let depContract = new web3.eth.Contract(
        JSON.parse(abi),
        contractDetails.address
      );
      let methodToCall = null;

      if (type === ContractUriType.BASEURI) {
        methodToCall = depContract.methods
          .setBaseURI(String(data[urlInputName]))
          .encodeABI();
      } else if (type === ContractUriType.CONTRACT_URI) {
        methodToCall = depContract.methods
          .updateContractURI(String(data[urlInputName]))
          .encodeABI();
      }

      let contractParams = {
        from: String(account),
        to: contractDetails.address,
        data: methodToCall,
      };

      web3.eth
        .estimateGas(contractParams)
        .then((gasEstimate) => {
          web3.eth
            .sendTransaction({ ...contractParams, gas: gasEstimate })
            .on("error", function (err: any) {
              toast.error("An unexpected error has occurred, please try again");
            })
            .on("receipt", function () {
              setBtnDisbaled(false);
              axios
                .put(API_URLS.CONTRACT_UPDATE, formParams)
                .then(function (res) {
                  onClose && onClose();
                  successHandler && successHandler();
                  toast.success(res?.data?.message);
                })
                .catch((error) => {
                  toast.error(
                    error?.response?.data?.message?.message ||
                      "Please try again later"
                  );
                });
            })
            .on("transactionHash", function (txn: string) {
              setTxnHash(txn);
            });
        })
        .catch(() => {
          setBtnDisbaled(false);
          toast.error("Could not estimate gas, please check your balance");
        });
    } catch (e: any) {
      setBtnDisbaled(false);
      toast.error(e?.message || "Please try again later");
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-group">
        <label className="col-form-label">URI</label>
        <input
          type="url"
          className={`form-control`}
          {...register(urlInputName, {
            required: "Field is Required",
          })}
        />
      </div>

      <button
        type="submit"
        disabled={btnDisabled}
        className="btn btn-primary my-3"
        value="Update"
      >
        Update
      </button>
    </form>
  );
};

export default UpdateContractUriForm;
