import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { API_URLS } from "../../config";

interface UpdateMetaDataBtnProps {
  nftId: number;
  successHandler?: any;
}

const UpdateMetaDataBtn = ({
  nftId,
  successHandler,
}: UpdateMetaDataBtnProps) => {
  const refreshDataHandler = () => {
    axios
      .post(API_URLS.UPDATE_NFT_META_DATA, { nftId })
      .then(function (res) {
        toast.success("Updated successfully");
        successHandler && successHandler();
      })
      .catch(function (error) {
        toast.error(error?.response?.data?.message || "Please try again later");
      });
  };
  return (
    <>
      <button
        onClick={() => refreshDataHandler()}
        type="button"
        className="btn btn-light"
        title="Refresh Data"
      >
        <i className="fa fa-refresh" aria-hidden="true" />
      </button>
    </>
  );
};

export default UpdateMetaDataBtn;
