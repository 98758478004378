import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";

import { API_URLS } from "../config";
import { useNavigate } from "react-router-dom";
import "./mynft.css";
import Sidebar from "./sidebar";
import Web3 from "web3";
import { useWeb3React } from "@web3-react/core";

import { toast } from "react-toastify";
import "./sidebar.css";
import "./maincards.css";

import * as qs from "query-string";

import {
  LineStyle,
  Timeline,
  TrendingUp,
  PermIdentity,
  Router,
} from "@material-ui/icons";
import { Link } from "react-router-dom";
import {
  sliceString,
  copyTokenId,
  getFileUrlFromMetaData,
  getNftName,
} from "../common/functions";
import { useLocation } from "react-router-dom";
import DisplayFile from "../components/DisplayFile";

const Mynft = () => {
  const [callotherApi, setCallotherApi] = useState<boolean>(false);

  const [collections, setCollections] = useState<any>([]);
  const [cardCollections, setCardCollections] = useState<any>([]);
  const [itemsPerPage, setItemsPerPage] = useState<any>(15);
  const [pageCount, setPageCount] = useState<number>(0);
  const [activePage, setActivePage] = useState<number>(1);
  const [selectedCollections, setSelectedCollections] = useState<number[]>([]);

  const { chainId, library } = useWeb3React();
  const { account } = useWeb3React();
  const Navigate = useNavigate();

  const Location = useLocation();

  useEffect(() => {
    if (account && chainId) {
      syncTransactions();
    }
  }, [account, chainId]);

  useEffect(() => {
    if (callotherApi && account && chainId) {
      fetchContractList();
    }
  }, [callotherApi, account, chainId]);

  const syncTransactions = () => {
    let params = {
      chain_id: chainId,
      address: account,
    };
    axios
      .get(API_URLS.SYNC_TRANSACTIONS, { params })
      .then((res) => {
        setCallotherApi(true);
      })
      .catch((error) => {
        setCallotherApi(true);
      });
  };
  const goTodetailsPageHandler = (contractAddress: any, tokenId: any) => {
    Navigate(`/mynft-details/${contractAddress}/${tokenId}`);
  };
  const fetchContractList = () => {
    let params: any = {
      owner_address: account,
      //owner_address: "0x4c1C0375a7FDd6013E113Fc1AD99Fe3e4721Ef15",
      chainId,
    };
    axios
      .get(API_URLS.OWNER_COLLECTIONS, { params })
      .then((res: any) => {
        setCollections(res.data);
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (callotherApi && account && chainId) {
      const parsed = qs.parse(Location.search);

      loadInitialData(parsed);
    }
  }, [Location, callotherApi, account, chainId]);

  const loadInitialData = (params: any) => {
    params.page = params?.page || 1;
    params.perPage = itemsPerPage;
    params.chainId = chainId;
    params.owner_address = account;
     //params.owner_address = "0x4c1C0375a7FDd6013E113Fc1AD99Fe3e4721Ef15";

    axios
      .get(API_URLS.MINT_NFT, { params })

      .then((res) => {
        setActivePage(params.page);

        if (
          !selectedCollections.length &&
          params.contract_id_list &&
          params.contract_id_list.length
        ) {
          let tempArr: number[] = [];

          let temSelectedContract = params.contract_id_list.split(",");
          temSelectedContract &&
            temSelectedContract.length &&
            temSelectedContract.map((selectedCon: string) => {
              tempArr.push(Number(selectedCon));
            });

          setSelectedCollections(tempArr);
        }

        setPageCount(res.data.data.totalPage);
        setCardCollections(res.data.data.data);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  const handlePageClick = async (indexNumber: any) => {
    filterItems({ page: indexNumber.selected + 1 });
  };

  const filterItems = (params: any) => {
    let parsed = qs.parse(Location.search, { arrayFormat: "comma" });

    let urlFilter = qs.stringify(
      { ...parsed, ...params },
      { arrayFormat: "comma" }
    );

    Navigate(`/mynft?${urlFilter}`);
  };

  const handleCollection = (contractId: number) => {
    let tempCollection: number[] = [...selectedCollections];

    if (tempCollection.includes(contractId)) {
      tempCollection.splice(tempCollection.indexOf(contractId), 1);
    } else {
      tempCollection.push(contractId);
    }

    setSelectedCollections(tempCollection);

    filterItems({ contract_id_list: tempCollection, page: 1 });
  };

  const imageUrlHandler = (metaData: any, mimeType:string |null=null) => {
    let defaultImage = "/no-image.jpeg";

    try {
      let metaDataJson = null;
      if (typeof metaData === "string") {
        metaDataJson = JSON.parse(metaData);
      } else {
        metaDataJson = metaData;
      }

      if (!metaDataJson) {
        return (
          <>
            <img src={defaultImage} alt="..." />
          </>
        );
      }
      const getMetaImage = getFileUrlFromMetaData(metaDataJson, mimeType);

      return (
        <>
          <DisplayFile
            placeholder={getMetaImage.placeholder.path}
            link={
              getMetaImage.placeholder.path
                ? getMetaImage.placeholder.path
                : getMetaImage.mainFile.path
            }
            mimeType={
              getMetaImage.placeholder.path
                ? getMetaImage.placeholder.mimeType
                : getMetaImage.mainFile.mimeType
            }
          />
        </>
      );
    } catch (error) {
      console.log("error", error);
      return (
        <>
          <img src={defaultImage} alt="..." />
        </>
      );
    }
  };

  const nftNameHandler = (metaData: any) => {
    let name = null;
    try {
      let metaDataJson = null;
      if (typeof metaData === "string") {
        metaDataJson = JSON.parse(metaData);
      } else {
        metaDataJson = metaData;
      }

      name = getNftName(metaDataJson);

      return name;
    } catch (error) {
      return name;
    }
  };

  return (
    <div className="container_nft">
      {/* start of sidebar */}
      <div className="sidebar">
        <div className="sidebarWrapper">
          <div className="sidebarMenu">
            <h3 className="sidebarTitle">Collections</h3>
            <ul className="sidebarList">
              {collections?.data?.length ? (
                collections.data.map((collection: any, index: number) => (
                  <li
                    key={index}
                    className={`sidebarListItem ${
                      selectedCollections.includes(collection.contractId)
                        ? "activeTab"
                        : ""
                    }  `}
                    onClick={() =>
                      handleCollection(Number(collection.contractId))
                    }
                  >
                    <LineStyle className="sidebarIcon" />
                    {collection?.contractName ||
                      sliceString(collection.address)}

                    <span className="tokenIds">
                      {copyTokenId(collection.token_ids)}
                    </span>
                  </li>
                ))
              ) : (
                <li>No collections found</li>
              )}
            </ul>
          </div>
        </div>
      </div>

      {/* end of sidebar */}
      <div className="others">
        <h6 className="wallet">Wallet Address: {account}</h6>

        <div className="container mx-auto mt-4">
          <div className="row">
            {cardCollections.map((cardData: any, index: number) => {
              return (
                <div
                  key={"card" + index}
                  className="col-md-4"
                  onClick={() => {
                    goTodetailsPageHandler(
                      cardData.contract_address,
                      cardData.token_id
                    );
                  }}
                >
                  <div className="card">
                    {imageUrlHandler(cardData.metadata, cardData.mimetype ? cardData.mimetype : null)}
                    {/*<img
                      src={imageUrlHandler(cardData.metadata) || ""}
                      alt="..."
                    />*/}
                    <div className="card-body">
                      <h6 className="card-subtitle mb-2 text-muted">
                        <b> Token#:</b> {cardData.token_id}
                      </h6>

                      <h6 className="card-subtitle mb-2 text-muted">
                        {nftNameHandler(cardData.metadata)}
                      </h6>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {pageCount > 0 ? (
          <ReactPaginate
            previousLabel={"previous"}
            //forcePage={activePage}
            nextLabel={"next"}
            breakLabel={"..."}
            pageCount={pageCount}
            pageRangeDisplayed={itemsPerPage}
            onPageChange={handlePageClick}
            containerClassName={"pagination justify-content-center"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        ) : (
          <h2>No data found</h2>
        )}
      </div>
    </div>
  );
};

export default Mynft;
