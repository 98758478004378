import React, { cloneElement } from "react";
import { useWeb3React } from "@web3-react/core";
import { Modal } from "react-bootstrap";
import { connectors, WalletNames } from "../web3/connectors";
import {changeModalSize} from '../common/functions'

interface Props {
	isOpen: boolean;
	onClose: () => void;
}

const SelectWalletModal = ({ isOpen, onClose }: Props) => {
	const { activate } = useWeb3React();

	const connectToWallet = (type: string) => {
		activate(connectors[type]);
		localStorage.setItem("provider", type);
        if(type === WalletNames.WALLET_CONNECT){
            changeModalSize()
        }
		onClose();
	};

	return (
		<Modal centered onHide={onClose} show={isOpen}>
			<Modal.Header closeButton>Wallets</Modal.Header>
			<Modal.Body className="d-flex justify-content-center flex-column">
				<button
					className="btn btn-warning mb-3"
					onClick={() => connectToWallet(WalletNames.INJECTED)}
				>
					METAMASK
				</button>

				<button
					className="btn btn-primary mb-3"
					onClick={() => connectToWallet(WalletNames.COINBASE)}
				>
					COINBASE
				</button>
				<button
					className="btn btn-success mb-3"
					onClick={() => connectToWallet(WalletNames.WALLET_CONNECT)}
				>
					WALLET CONNECT
				</button>
			</Modal.Body>
		</Modal>
	);
};

export default SelectWalletModal;
