import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { CurrentStatusName, MakeItemProgress } from "../../containers/listitem";
import ManageRoyaltyForm from "./manageRoyaltyForm";
import "./steps.css";

interface SetApprovalModalProps {
  isOpen: boolean;
  onClose: () => void;
  successHandler?: () => void;
  handleMakeItemProgress: MakeItemProgress;
}
type CurrentStatusNameString = keyof typeof CurrentStatusName;

const SetApprovalModal = ({
  isOpen,
  onClose,
  successHandler,
  handleMakeItemProgress,
}: SetApprovalModalProps) => {
  const stepCounterHandler = (
    step: number,
    stepName: CurrentStatusNameString
  ): JSX.Element => {
    let status: string = "";
    let statusIcon: JSX.Element;
    if (handleMakeItemProgress.currentStep === step) {
      statusIcon = <i className="fa fa-spinner fa-spin fa-2x" />;
      status = "active";
    } else if (handleMakeItemProgress.currentStep < step) {
      statusIcon = <i className=" step-icon fa fa-clock fa-2x" />;
      //waiting
    } else if (handleMakeItemProgress.currentStep > step) {
      statusIcon = <i className="fa fa-check fa-2x" />;
      status = "completed";
    } else {
      statusIcon = <></>;
    }
    return (
      <div className={`stepper-item ${status}`} >
        <div className="step-counter">{statusIcon} </div>
        <div className="step-name">{CurrentStatusName[stepName]} </div>
      </div>
    );
  };
  return (
    <>
      <Modal centered onHide={onClose} show={isOpen}>
        <Modal.Header closeButton>Complete Your Listing</Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="stepper-wrapper">
              {Object.keys(CurrentStatusName).map(
                (key: CurrentStatusNameString, index: number) => {
                  return (
                    <div key={index}>{stepCounterHandler(index + 1, key)}</div>
                  );
                }
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default SetApprovalModal;
