import axios from "axios";
import React from "react";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import FormTexAreatInput from "../components/form_elements/textAreaInput";
import FormTextInput from "../components/form_elements/textInput";
import { API_URLS } from "../config";
import { setSettings } from "../redux/reducers/common.reducer";
import { RootState } from "../redux/store";

const Settings = () => {
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    register,
    formState: { errors },
  } = useForm<any>({});
  const dispatch = useDispatch();

  const allSettings = useSelector((state: RootState) => state.common.settings);
  console.log("allSettings",allSettings)

  const [currentTab, setCurrentTab] = useState("");
  const [allTabs, setAllTabs] = useState([]);
  const [settings, setStateSettings] = useState([]);

  useEffect(() => {
    if (allSettings.length) {
      let groupTabs: any = [];
      let name_of_groups = allSettings;

      name_of_groups.map((elem: any) => {
        if (!groupTabs.includes(elem.group)) {
          groupTabs.push(elem.group);
        }
      });
      setAllTabs(groupTabs);
      setCurrentTab(groupTabs[0]);
      setStateSettings(allSettings);
    }
  }, [allSettings]);

  const onSubmit = handleSubmit((data) => {
    axios
      .patch(API_URLS.UPDATE_SETTINGS, data)
      .then(function (response) {
        toast.success("Setting Updated");
        axios
          .get(API_URLS.GET_SETTINGS)
          .then((res: any) => {
            dispatch(setSettings(res.data.data));
          })
          .catch(() => {});
      })
      .catch(function (error) {
        console.log(error);
      });

    // alert(JSON.stringify(data));
  });
  return (
    <div className="container" style={{ marginTop: "20px" }}>
      <ul className="nav nav-tabs">
        {allTabs.map((val: any) => {
          return (
            <li className="nav-item" onClick={() => setCurrentTab(val)}>
              <a
                className={`nav-link ${val === currentTab ? "active" : ""}`}
                href="#"
              >
                {val}
              </a>
            </li>
          );
        })}
      </ul>
      {/* {console.log(settings.filter((val:any)=>val.group===currentTab))} */}

      <form onSubmit={onSubmit} className="tab-content">
        {settings
          .filter((val: any) => val.group === currentTab)
          .map((elem: any) => {
            if (elem.field_type === "text") {
              return (
                <div className="form-group">
                  <label className="form-label">{elem.field_label}</label>
                  <Controller
                    key={elem.id}
                    defaultValue={elem?.value || ""}
                    control={control}
                    name={elem.key}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                    }) => (
                      <FormTextInput
                        name={name}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        inputRef={ref}
                        type="text"
                        error={errors.description}
                        placeholder={`Enter ${elem.field_label}`}
                      />
                    )}
                  />
                </div>
              );
            } else if (elem.field_type === "textarea") {
              return (
                <div className="form-group">
                  <label className="form-label">{elem.field_label}</label>
                  <Controller
                    key={elem.id}
                    defaultValue={elem?.value || ""}
                    control={control}
                    name={elem.key}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                    }) => (
                      <FormTexAreatInput
                        name={name}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        inputRef={ref}
                        type="text"
                        error={errors.description}
                        placeholder={`Enter ${elem.label}`}
                        rows={4}
                      />
                    )}
                  />
                </div>
              );
            }
			else if (elem.field_type === "password") {
				return (
				  <div className="form-group">
					<label className="form-label">{elem.field_label}</label>
					<Controller
					  key={elem.id}
					  defaultValue={elem?.value || ""}
					  control={control}
					  name={elem.key}
					  render={({
						field: { onChange, onBlur, value, name, ref },
					  }) => (
						<FormTextInput
						  name={name}
						  onChange={onChange}
						  onBlur={onBlur}
						  value={value}
						  inputRef={ref}
						  type="password"
						  error={errors.description}
						  placeholder={`Enter ${elem.field_label}`}
						/>
					  )}
					/>
				  </div>
				);
			  }

          })}
        <button type="submit" className="btn btn-success">
          Submit
        </button>
      </form>
    </div>
  );
};

export default Settings;
