import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import Web3 from "web3";
import { useWeb3React } from "@web3-react/core";
import { switchNetwork } from "../web3/functions";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";

import {
  useCommonSelector,
  useRateUSD,
  useSettingsSelector,
} from "../hooks/selectors";
import {
  AVAX_CHAINS,
  CHAIN_OPTS,
  ETH_CHAINS,
  MATIC_CHAINS,
  WalletNames,
  DEPLOY_CHAIN_OPTS,
} from "../web3/connectors";
import Select from "react-select";
import { setData } from "../redux/reducers/table.reducer";
interface FetchAbiResponseInterface {
  success: boolean;
  abi?: any;
  bytecode?: any;
}

import { API_URLS } from "../config";
import MintTable from "../components/deploy/mintTable";
import {
  checkNumber,
  copyText,
  copyTransaction,
  copyContractAddress,
  copyFromAddress,
  sliceString,
  getPercentageAmount,
} from "../common/functions";
import MintPremintForm from "../components/deploy/mintPreMintForm";
import MintPreMintModal from "../components/deploy/mintPremintModal";
import UpdateContractUriModal from "../components/deploy/updateContractUriModal";

export interface mintPremintSelected {
  index: number;
  type: string;
}
export interface SelectedContractInterface {
  contractDetails: any;
  type: ContractUriType;
}
export enum ContractUriType {
  BASEURI = "baseUri",
  CONTRACT_URI = "contractUri",
}

const DeployPage = () => {
  const { account } = useWeb3React();
  const [upsertContractUriModal, setUpsertContractUriModal] =
    useState<boolean>(false);
  const [selectedContract, setSelectedContract] =
    useState<SelectedContractInterface>();
  const [itemsPerPage, setItemsPerPage] = useState<any>(10);
  const [activePage, setActivePage] = useState<number>(1);

  const [pageCount, setPageCount] = useState<number>(0);
  const [contractData, setContractData] = useState<any[]>([]);

  const [mintModal, setMintModal] = useState<boolean>(false);

  const ethereum = (window as any).ethereum;

  const common = useCommonSelector();
  const { chainId, library } = useWeb3React();

  const findValue = useSettingsSelector();

  const dispatch = useDispatch();
  const rateFn = useRateUSD();

  const [chain, setChain] = useState<any>("");
  const [walletAddress, setWalletAddress] = useState<string>("");
  const [contractName, setContractName] = useState<string>("");
  const [contractSymbol, setContractSymbol] = useState<string>("");

  const [baseTokenURI, setbaseTokenURI] = useState<string>("");
  const [contractURI, setcontractURI] = useState<string>("");

  const [commisionPercent, setCommisionPercent] = useState<any>(0);
  const [rate, setRate] = useState(1);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [indVal, setIndexVal] = useState<mintPremintSelected>();

  useEffect(() => {
    let initialPage = 1;
    setActivePage(initialPage);
    loadInitialData(initialPage);
  }, [itemsPerPage]);

  useEffect(() => {
    setRate(rateFn(chain.value));
  }, [chain]);

  const loadInitialData = (selectedPage = 1) => {
    let params: any = {
      page: selectedPage,
      perPage: itemsPerPage,
      source: "self",
    };
    fetchContracts(params);
  };

  // fetch the congtact List
  const fetchContracts = (searchParams: any = {}) => {
    axios
      .get(API_URLS.GET_CONTRACTS, { params: searchParams })
      .then((res: any) => {
        setPageCount(res.data.data.totalPage);
        setContractData(res.data.data.data);
      })
      .catch(() => {});
  };

  const handlePageClick = async (data: any) => {
    let currentPage = data.selected + 1;
    setActivePage(currentPage);
    loadInitialData(currentPage);
  };

  // deploy the contract from UI using the connected account
  const deployHandler = async () => {
    // isValid(baseTokenURI)
    if (!chain) {
      toast.error("Please select a network");
      return;
    } else if (!Web3.utils.isAddress(walletAddress)) {
      toast.error("Invalid wallet address given");
      return;
    } else if (isNaN(commisionPercent)) {
      toast.error("commission  percent must be a number");
      return;
    } else if (!isValidHttpUrl(baseTokenURI)) {
      toast.error("Base Token must be a URL");
      return;
    } else if (!isValidHttpUrl(contractURI)) {
      toast.error("Contract must be a URL");
      return;
    } else if (!contractString(contractName)) {
      toast.error("Contract Name is required");
      return;
    } else if (!contractString(contractSymbol)) {
      toast.error("Contract Symbol is required");
      return;
    }
    const web3 = new Web3(library.provider);
    let currentChainId = await web3.eth.getChainId();
    let selectedChain = chain.value;

    if (currentChainId !== selectedChain) {
      switchNetwork(String(chain.value), library).then(() => deployHandler());
      return;
    }
    const abiFetchRes: FetchAbiResponseInterface = await fetchAbiAndByteCode({
      walletAddress: account,
      chainId: chain.value,
      name: contractName,
      symbol: contractSymbol,
    });
    if (abiFetchRes.success && (!abiFetchRes.abi || !abiFetchRes.bytecode)) {
      toast.error("Abi or bytecode is missing");
      return;
    }
    let contract = new web3.eth.Contract(abiFetchRes.abi);

    const bytecodeWithEncodedParameters = contract
      .deploy({
        data: abiFetchRes.bytecode,
        arguments: [baseTokenURI, contractURI],
      })
      .encodeABI();
    web3.eth
      .estimateGas({
        data: bytecodeWithEncodedParameters,
      })
      .then((gasEstimate) => {
        let newGasEstimate = Math.round(
          getPercentageAmount(gasEstimate, 20, true)
        );
        console.log("estimated", newGasEstimate);

        new web3.eth.Contract(abiFetchRes.abi)
          .deploy({
            data: abiFetchRes.bytecode,
            arguments: [baseTokenURI, contractURI],
          })
          .send({ from: String(account), gas: newGasEstimate })
          .on("error", function (err: any) {
            toast.error("An unexpected error has occurred, please try again");
          })
          .on("transactionHash", function (txn: string) {
            console.log("txn", txn);
            axios
              .post(API_URLS.SAVE_CONTRACT, {
                txn_hash: txn,
                abi: abiFetchRes.abi,
                byte_code: abiFetchRes.bytecode,
                chain_id: chain.value,
                wallet_address: walletAddress,
                commission_percent: commisionPercent,
                base_token_uri: baseTokenURI,
                contract_uri: contractURI,
                name: contractName,
                symbol: contractSymbol,
              })
              .then(function (res) {
                setWalletAddress("");
                setCommisionPercent(0);
                setbaseTokenURI("");
                setcontractURI("");
                setContractName("");
                setContractSymbol("");
                toast.success("Transaction hash saved successfully");
              })
              .catch(function (error) {
                toast.error(
                  error?.response?.data?.message ||
                    "Unable to save transaction hash"
                );
              });
          });
      })
      .catch((err) => {
        console.log(err.message);
        toast.error("Could not estimate gas, please check your balance");
      });
  };

  function isValidHttpUrl(text: any) {
    let url;
    try {
      url = new URL(text);
    } catch (err: any) {
      return false;
    }
    // console.log("contracturl",true)
    if (url.protocol === "http:" || url.protocol === "https:") {
      return true;
    } else {
      return false;
    }
  }

  function contractString(text: any) {
    if (text === "") {
      return false;
    }
    return true;
  }
  // get contract API code from API (contract compiled in backend)
  const fetchAbiAndByteCode = (params: any) => {
    return new Promise<FetchAbiResponseInterface>((resolve, reject) => {
      axios
        .post(API_URLS.GET_ABI_AND_BYTECODE, params)
        .then(function (res) {
          resolve({
            success: true,
            abi: res.data?.data?.abi,
            bytecode: res.data?.data?.bytecode,
          });
        })
        .catch(function (error) {
          toast.error(
            error?.response?.data?.message || "Please try again later"
          );
          resolve({
            success: false,
          });
        });
    });
  };

  // call the contract mint function to mint the token, amount is the no. of tokens to mint
  const mint = async (index: number, amount: number) => {
    try {
      let row = { ...contractData[index] };
      let abi = row.abi;
      abi = abi.replace("'", "");
      abi = abi.slice(0, -1);
      row.abi = abi;

      const web3 = new Web3(library.provider);
      let currentChainId = await web3.eth.getChainId();
      let reqdChain = row.chain_id;
      if (currentChainId !== reqdChain) {
        // switch to contract deployed network if user is not already on the same network
        switchNetwork(String(reqdChain), library).then(() =>
          mint(index, amount)
        );
        return;
      }

      // call the mintNFT function of the contract
      let depContract = new web3.eth.Contract(JSON.parse(row.abi), row.address);
      let commissionAddress = row.wallet_address;
      let mintAmount = amount;
      let commissionPercent = row.commission_percent;
      let commissionPercentAmount = getPercentageAmount(
        mintAmount,
        commissionPercent
      );
      let price: number = 0.001;
      if (amount > 1) {
        price = price * amount;
      }

      let params = {
        from: String(account),
        to: row.address,
        value: Web3.utils.toWei(String(price), "ether"),
        data: depContract.methods
          .mintNFT(
            Number(mintAmount),
            commissionAddress,
            Number(commissionPercent)
          )
          .encodeABI(),
      };
      web3.eth
        .estimateGas(params)
        .then((gasEstimate) => {
          web3.eth
            .sendTransaction({ ...params, gas: gasEstimate })
            .on("error", function (err: any) {
              toast.error("An unexpected error has occurred, please try again");
            })
            .on("transactionHash", function (txn: string) {
              axios
                .post(API_URLS.TRANSACTION_SAVE, {
                  // save the transaction# in db and keeping checking its status in background
                  txn_hash: txn,
                  contract_id: row.id,
                  chain_id: row.chain_id,
                  from_address: account,
                  type: "mint",
                  price,
                })
                .then(function (res) {
                  toast.success("Transaction hash saved successfully");
                })
                .catch(function (error) {
                  toast.error(
                    error?.response?.data?.message ||
                      "Unable to save transaction hash"
                  );
                });
            });
        })
        .catch(() => {
          toast.error("Could not estimate gas, please check your balance");
        });
    } catch (err: any) {
      console.log("error", err.message);
      toast.error("Something went wrong , please try again later");
    }
  };

  // call the contract printmint function to premint the token, amount is the no. of tokens to mint
  const premintHandler = async (index: number, amount: number) => {
    try {
      if (!account) {
        toast.error("Account is not set yet");
        return;
      }
      let row = { ...contractData[index] };
      let abi = row.abi;
      abi = abi.replace("'", "");
      abi = abi.slice(0, -1);
      row.abi = abi;

      const web3 = new Web3(library.provider);
      let currentChainId = await web3.eth.getChainId();
      let reqdChain = row.chain_id;

      if (currentChainId !== reqdChain) {
        switchNetwork(String(reqdChain), library).then(() =>
          premintHandler(index, amount)
        );
        return;
      }
      if (account.toLocaleLowerCase() !== row.from_address) {
        toast.error("Your are not the owner of the contract");
        return;
      }

      // call the premint function, can be called only by the contract owner
      let depContract = new web3.eth.Contract(JSON.parse(row.abi), row.address);
      //let mintAmount = row.amount;
      let mintAmount = amount;

      let params = {
        from: String(account),
        to: row.address,
        data: depContract.methods.preMintNFT(Number(mintAmount)).encodeABI(),
      };
      web3.eth
        .estimateGas(params)
        .then((gasEstimate) => {
          web3.eth
            .sendTransaction({ ...params, gas: gasEstimate })
            .on("error", function (err: any) {
              toast.error("An unexpected error has occurred, please try again");
            })
            .on("transactionHash", function (txn: string) {
              console.log("transactionHash", txn);

              axios
                .post(API_URLS.TRANSACTION_SAVE, {
                  txn_hash: txn,
                  contract_id: row.id,
                  chain_id: row.chain_id,
                  from_address: account,
                  type: "premint",
                })
                .then(function (res) {
                  toast.success("Transaction hash saved successfully");
                })
                .catch(function (error) {
                  toast.error(
                    error?.response?.data?.message ||
                      "Unable to save transaction hash"
                  );
                });
            });
        })
        .catch(() => {
          toast.error("Could not estimate gas, please check your balance");
        });
    } catch (err) {
      console.log("error");
      toast.error("Something went wrong , please try again later");
    }
  };
  const refreshContractDataHandler = (): void => {
    loadInitialData(activePage);
  };

  return (
    <div className="container">
      <div className="form-group">
        <label className="form-label">Select Network</label>
        <Select
          className=" w-50"
          options={DEPLOY_CHAIN_OPTS}
          value={chain}
          onChange={setChain}
        />
      </div>

      <div className="form-group">
        <label className="form-label">Wallet Address</label>
        <input
          type="text"
          placeholder="Enter Wallet Address"
          className="form-control w-50"
          onChange={(e) => setWalletAddress(e.target.value)}
          value={walletAddress}
        />
      </div>

      <div className="form-group">
        <label className="form-label">Commission Percent</label>
        <input
          type="text"
          min={0}
          max={100}
          placeholder="Enter Wallet Address"
          className="form-control w-50"
          onChange={(e) => {
            checkNumber(e);
            setCommisionPercent(e.target.value);
          }}
          value={commisionPercent}
        />
      </div>

      <div className="form-group">
        <label className="form-label">Token URI</label>
        <input
          type="text"
          placeholder="Enter Token URI"
          className="form-control w-50"
          onChange={(e) => setbaseTokenURI(e.target.value)}
          value={baseTokenURI}
        />
      </div>

      <div className="form-group">
        <label className="form-label">Contract URI</label>
        <input
          type="text"
          placeholder="Enter Contract URI"
          className="form-control w-50"
          onChange={(e) => setcontractURI(e.target.value)}
          value={contractURI}
        />
      </div>

      <div className="form-group">
        <label className="form-label">Contract Name</label>
        <input
          type="text"
          placeholder="Enter Contract Name"
          className="form-control w-50"
          onChange={(e) =>
            setContractName(e.target.value && e.target.value.replace(/ /g, ""))
          }
          value={contractName}
        />
      </div>

      <div className="form-group">
        <label className="form-label">Contract Symbol</label>
        <input
          type="text"
          placeholder="Enter Contract Symbol"
          className="form-control w-50"
          onChange={(e) => setContractSymbol(e.target.value)}
          value={contractSymbol}
        />
      </div>

      <button className="btn btn-dark mt-5 btn-spacing" onClick={deployHandler}>
        Deploy
      </button>

      <br />
      <div className="container mb-4 mt-5">
        <h5>Deploys</h5>
        <table className="table w-100">
          <thead className="thead-dark">
            <tr>
              <th>Txn_Hash</th>
              <th>Contract Address</th>
              <th>From Address</th>
              <th>Network</th>
              <th>Chain Name</th>
              <th>Status</th>
              <th>Contract Name</th>
              <th>Contract Symbol</th>

              <th>Created At</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {contractData.length ? (
              contractData.map((row: any, index: number) => (
                <tr key={row.id}>
                  <td
                    title={row.txn_hash}
                    onClick={() => copyTransaction(row.txn_hash, row.chain_id)}
                  >
                    {sliceString(row.txn_hash)}
                  </td>
                  <td
                    title={row.address}
                    onClick={() =>
                      copyContractAddress(row.address, row.chain_id)
                    }
                  >
                    {sliceString(row.address)}
                  </td>
                  <td
                    onClick={() =>
                      copyFromAddress(row.from_address, row.chain_id)
                    }
                  >
                    {sliceString(row.from_address)}
                  </td>
                  <td>{row.network}</td>
                  <td>
                    {
                      DEPLOY_CHAIN_OPTS.find(
                        (opt: any) => opt.value === row.chain_id
                      )?.label
                    }
                  </td>
                  <td>{row.status}</td>
                  <td>{row.name === null ? "N/A" : row.name}</td>
                  <td>{row.symbol === null ? "N/A" : row.symbol}</td>

                  <td>{String(new Date(row.created_at))}</td>
                  <td>
                    {row.status === "deployed" && (
                      <div className="btn-group">
                        {/* <button
                          className="btn-spacing btn btn-dark"
                          onClick={() => mint(index)}
                        >
                          Mint
                        </button> */}
                        <button
                          className="btn-spacing btn btn-dark"
                          onClick={() => {
                            setIndexVal({
                              index,
                              type: "mint",
                            });
                            setShowModal(true);
                          }}
                        >
                          Mint
                        </button>

                        {row.from_address &&
                        account &&
                        row.from_address.toLowerCase() ===
                          account.toLowerCase() ? (
                          <>
                            <button
                              className="btn-spacing btn btn-dark"
                              onClick={() => {
                                setIndexVal({
                                  index,
                                  type: "premint",
                                });
                                setShowModal(true);
                              }}
                            >
                              Premint
                            </button>

                            <button
                              className="btn-spacing btn btn-primary"
                              onClick={() => {
                                setUpsertContractUriModal(true);
                                setSelectedContract({
                                  type: ContractUriType.BASEURI,
                                  contractDetails: row,
                                });
                              }}
                              title="Update Base URI"
                            >
                              <i className="fas fa-home" />
                            </button>

                            <button
                              className="btn-spacing btn btn-warning"
                              onClick={() => {
                                setUpsertContractUriModal(true);
                                setSelectedContract({
                                  type: ContractUriType.CONTRACT_URI,
                                  contractDetails: row,
                                });
                              }}
                              title="Update Contract URI"
                            >
                              <i className="fas fa-coins" />
                            </button>
                          </>
                        ) : null}
                      </div>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td>No transaction found</td>
              </tr>
            )}
          </tbody>
        </table>
        <ReactPaginate
          previousLabel={"previous"}
          nextLabel={"next"}
          breakLabel={"..."}
          pageCount={pageCount}
          // marginPagesDisplayed={2}
          pageRangeDisplayed={itemsPerPage}
          onPageChange={handlePageClick}
          containerClassName={"pagination justify-content-center"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"}
        />
      </div>
      <div className="container mb-4 mt-5">
        <h5>NFTs</h5>
        <MintTable />
      </div>
      {indVal && indVal.type ? (
        <MintPreMintModal
          isOpen={showModal}
          mint={mint}
          premint={premintHandler}
          indVal={indVal}
          onClose={() => setShowModal(false)}
        />
      ) : null}
      {selectedContract ? (
        <UpdateContractUriModal
          isOpen={upsertContractUriModal}
          selectedContract={selectedContract}
          onClose={() => {
            setUpsertContractUriModal(false);
            setSelectedContract(undefined);
          }}
          successHandler={refreshContractDataHandler}
        />
      ) : null}
    </div>
  );
};

export default DeployPage;
