import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import Web3 from "web3";
import { useWeb3React } from "@web3-react/core";
import { switchNetwork } from "../web3/functions";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { useLocation, useParams } from "react-router-dom";

import Sellfiat from "./sellfiat";
import queryString from "query-string";

import {
  useCommonSelector,
  useRateUSD,
  useSettingsSelector,
} from "../hooks/selectors";
import {
  AVAX_CHAINS,
  CHAIN_OPTS,
  ETH_CHAINS,
  MATIC_CHAINS,
  WalletNames,
} from "../web3/connectors";
import Select from "react-select";
import { setData } from "../redux/reducers/table.reducer";

import Table from "../table/table";
import { API_URLS } from "../config";
import Buypayment from "./buyPayment";
import Sellpayment from "./sellPayment";

import SellFiatPayment from "./sellfiatpayment";

import { ContactSupportOutlined } from "@material-ui/icons";
import UserOrderList from "../components/order/UserOrderList";
import UserSellList from "../components/order/UserSellFiatList";
import { getPercentageAmount } from "../common/functions";

const MToken = () => {
  const orderListRef = useRef();
  const { account } = useWeb3React();

  const ethereum = (window as any).ethereum;
  const common = useCommonSelector();
  const { chainId, library } = useWeb3React();
  const findValue = useSettingsSelector();
  const buyPercent = findValue("buy_mToken")?.value / 100;
  const sellPercent = findValue("convert_mToken")?.value / 100;

  const dispatch = useDispatch();
  const rateFn = useRateUSD();

  const [amount, setAmount] = useState("");
  const [chain, setChain] = useState<any>("");
  const [rate, setRate] = useState(1);
  const [status, setStatus] = useState(true);
  const [sellStatus, setSellStatus] = useState(true);
  const [paymentid, setPaymentId] = useState<boolean>(true);
  const [buyFiatUrl, setbuyFiatUrl] = useState<any>("");
  const Location = useLocation();

  useEffect(() => {
    let parsed = queryString.parse(Location.search);
    console.log("parsed", parsed);
    let orderId;

    if (parsed.id) {
      orderId = parsed.id;
    } else if (parsed.orderId) {
      orderId = parsed.orderId;
    }

    let wyreTxn = parsed?.wyreTxn;

    if (wyreTxn === "failed" && account) {
      toast.error("Failed to process the transaction");
    }
    if (orderId && account) {
      console.log("here");
      orderSaveHandler({
        orderId,
        walletAddress: account,
      });
    }
  }, [Location, account]);

  useEffect(() => {
    if (account) {
      let params = {
        walletAddress: account,
      };
      axios
        .get(API_URLS.MTOKEN, { params })
        .then((res) => {
          if (res.data.data.status === "ACTIVE") {
            setPaymentId(false);
          } else {
            setPaymentId(true);
          }
        })
        .catch((error) => {
          // toast.error(error?.response?.data?.message);
        });
    }
  }, [account]);

  useEffect(() => {
    setRate(rateFn(chain.value));
  }, [chain]);

  const orderSaveHandler = (params: any) => {
    axios
      .post(API_URLS.SAVE_ORDER_TXN, params)
      .then((res) => {
        let orderData = res?.data?.data;

        if (orderData.mtoken_to_add && orderData.doAddMtoken) {
          let prevAmount = localStorage.getItem("mTokens") || 0;
          localStorage.setItem(
            "mTokens",
            String(Number(prevAmount) + Number(orderData.mtoken_to_add))
          );

          
        }


        const currentRef: any = orderListRef.current;

        currentRef.resetDataHandler && currentRef.resetDataHandler();
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  const submitAmount = () => {
    if (+amount <= 0) {
      toast.error("Please provide an amount");
    } else if (!chain) {
      toast.error("Please select a network");
    } else if (!library) {
      toast.error("Please connect a wallet from the home tab");
    } else if (chainId !== chain.value) {
      switchNetwork(String(chain.value), library).then(() =>
        transactWithWallet()
      );
    } else {
      transactWithWallet();
    }
  };

  const submitSellHandler = () => {
    let currentAmount = localStorage.getItem("mTokens") || 0;
    if (+amount <= 0) {
      toast.error("Please provide an amount");
    } else if (amount > currentAmount) {
      toast.error("Amount value cannot be greater than actual balance");
    } else if (!chain) {
      toast.error("Please select a network");
    } else {
      let amountToSend = Number(amount) - sellPercent * Number(amount);

      let convertedAmount = (amountToSend / rate).toFixed(6);

      convertedAmount = Web3.utils.toWei(String(convertedAmount), "ether");

      let params = {
        amount: convertedAmount,
        walletAddress: account,
        chainId: chain.value,
      };

      axios
        .post(API_URLS.MAKE_PAYMENT, params)
        .then(function (res) {
          localStorage.setItem(
            "mTokens",
            String(Number(currentAmount) - Number(amount))
          );
          setAmount("0");
          toast.success(res?.data?.message || "Payment done");
        })
        .catch(function (error) {
          toast.error(
            error?.response?.data?.message || "Please try again later"
          );
        });
    }
  };

  const payeeAddress = () => {
    let chainID = chainId ?? 1;
    if (ETH_CHAINS.includes(Number(chainID))) {
      return findValue("eth_payout_addres")?.value;
    } else if (AVAX_CHAINS.includes(Number(chainID))) {
      return findValue("avax_payout_addres")?.value;
    } else if (MATIC_CHAINS.includes(Number(chainID))) {
      return findValue("matic_payout_addres")?.value;
    }
  };

  const transactWithWallet = () => {
    const web3 = new Web3(library.provider);
    library.provider
      .request({ method: "eth_requestAccounts" })
      .then((newAccounts: string[]) => {
        let finalUSD = buyPercent * Number(amount) + Number(amount);
        let converted = (finalUSD / rate).toFixed(6);
        // (buyPercent * Number(amount) + Number(amount)) / rate
        let value = Web3.utils.toWei(String(converted), "ether");
        let params = {
          from: newAccounts[0].toLowerCase(),
          to: payeeAddress().toLowerCase(),
          value,
        };

        web3.eth
          .sendTransaction(params)
          .on("transactionHash", (txn: string) => {
            dispatch(
              setData({
                txn,
                currentDateTime: Date().toLocaleString(),
                Address: account,
                AmountPayed: converted,
              })
            );

            let prevAmount = localStorage.getItem("mTokens") || 0;
            localStorage.setItem(
              "mTokens",
              String(Number(prevAmount) + Number(amount))
            );
            setAmount("0");
          })
          .on("error", (err: any) => {
            if (err.code === 4001) {
              toast.error("Please accept the transaction to continue");
            } else {
              toast.error("Some error occurred, please try again");
            }
          });
      });
  };

  const buyonBoarding = () => {
    let params: any = {
      mToken: amount,
      chainId,
      walletAddress: account,
    };
    axios
      .post(API_URLS.MTOKEN_BUY_ONBOARDING, params)
      .then(function (res) {
        let buy_fiat_url = res?.data?.data?.url;

        if (!buy_fiat_url) {
          toast.error("Please try again later");
          return;
        }

        window.open(`${buy_fiat_url}`, "_blank");
      })
      .catch(function (error) {
        toast.error(
          error?.response?.data?.message?.message || "Please try again later"
        );
      });
  };

  return (
    <div className="container">
      <div className="form-group">
        <label className="form-label">Amount (MToken)</label>
        <input
          type="text"
          placeholder="Enter Numeric Value"
          className="form-control w-50"
          onChange={(e) => setAmount(e.target.value)}
          value={amount}
        />

        {rate && chain && (
          <>
            <h6>
              Conversion: {amount} MToken = {Number(amount || "0") / rate}{" "}
              {chain?.unit}
            </h6>
            <h6>
              Amount to pay (Buy):{" "}
              {(buyPercent * Number(amount) + Number(amount)) / rate}{" "}
              {chain?.unit}
              <small>
                {" "}
                <button className="btn btn-dark btn-sm " onClick={submitAmount}>
                  Buy with CRYPTO
                </button>
                {/* <button
                  className="btn btn-dark btn-sm m-2"
                  onClick={() => setStatus(!status)}
                >
                  Buy with FIAT
                </button> */}
                <button
                  className="btn btn-dark btn-sm m-2"
                  onClick={buyonBoarding}
                >
                  Buy with FIAT
                </button>
              </small>
            </h6>

            <h6>
              Amount to pay (Sell):{" "}
              {(Number(amount) - sellPercent * Number(amount)) / rate}{" "}
              {chain?.unit}{" "}
              <small>
                {" "}
                <button
                  className="btn btn-dark btn-sm"
                  onClick={submitSellHandler}
                >
                  Sell with CRYPTO
                </button>
                <button
                  disabled={paymentid}
                  className="btn btn-dark btn-sm m-2"
                  onClick={() => setSellStatus(!sellStatus)}
                >
                  Sell with FIAT
                </button>{" "}
                <span>
                  <Link to="/sellfiat">Payment Method</Link>
                </span>
              </small>
            </h6>
          </>
        )}

        <h6>1 MToken = $1</h6>
        <h6>Balance: {localStorage.getItem("mTokens") || 0} MToken</h6>
        {/* <span> <Link  to="/sellfiat">
         Payment Method
        </Link></span> */}
      </div>
      {status ? (
        <Select options={CHAIN_OPTS} value={chain} onChange={setChain} />
      ) : (
        <Buypayment amount={amount} />
      )}

      {sellStatus ? null : <Sellpayment amount={amount} />}

      <Table />
      <UserOrderList ref={orderListRef} />
      <UserSellList />
    </div>
  );
};

export default MToken;
