import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import Web3 from "web3";
import FormTextInput from "../form_elements/textInput";
import axios from "axios";
import { toast } from "react-toastify";
import { API_URLS } from "../../config";
import { FetchHiddednContract } from "../../containers/listitem";
import { useWeb3React } from "@web3-react/core";

interface ManageRoyaltyFormProps {
  contractAddress: string;
  royaltyWallet?: string | null;
  royaltyPercent?: number | null;
  successHandler?: () => void;
}
const addUpdateFormScehma = yup.object().shape({
  royalty_wallet: yup
    .string()
    .test("validateAddress", `Must be a valid wallet address`, (val: any) => {
      if (Web3.utils.isAddress(val)) {
        return true;
      } else {
        return false;
      }
    })
    .required("Field is required"),
  royalty_percent: yup
    .number()
    .typeError("Value must be a number")
    .min(0, "Value must be a positive value")
    .max(100, "Value must be less than or equal to 100")
    .required("Field is required"),
});

const ManageRoyaltyForm = ({
  contractAddress,
  royaltyWallet,
  royaltyPercent,
  successHandler,
}: ManageRoyaltyFormProps) => {
  const {
    register,
    control,
    setValue,
    setError,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(addUpdateFormScehma),
  });
  const { chainId, library } = useWeb3React();
  const [sellContract, setSellContract] = useState<any>(null);
  const [txnSellContract, setTxnSellContract] = useState<any>(null);
  const { account } = useWeb3React();
  useEffect(() => {
    setTimeout(() => {
      setValue("royalty_wallet", royaltyWallet ? royaltyWallet : "");
      setValue(
        "royalty_percent",
        royaltyPercent && !isNaN(royaltyPercent) ? royaltyPercent : "0"
      );
    }, 500);
  }, []);

  // sale contract

  useEffect(() => {
    if (chainId) {
      getSaleContractHandler(chainId);
    }
  }, [chainId]);
  const getSaleContractHandler = async (chainId: number) => {
    let params: FetchHiddednContract = {
      onlyHidden: true,
      singleData: true,
      chainId,
      source: "sale-contract",
    };
    const getDetails = await fetchContracts(params);
    if (getDetails && getDetails.success) {
      setSellContract(getDetails);
    }
  };
  const fetchContracts = (seatchParams: FetchHiddednContract) => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(API_URLS.GET_CONTRACTS, { params: { ...seatchParams } })
        .then(function (res) {
          resolve({
            success: res?.data?.data?.id ? true : false,
            ...res.data.data,
          });
        })
        .catch(function (error) {
          resolve({
            success: false,
          });
        });
    });
  };

  const onSubmit = (values: any) => {
    let formParam = { ...values, contract_address: contractAddress };
    console.log("formParam", formParam);

    if (sellContract?.abi && library && library.provider) {
      const web3 = new Web3(library.provider);
      let sellAbi = JSON.parse(sellContract.abi);
      let sellByteCode = JSON.parse(sellContract.byte_code);
      let depContract = new web3.eth.Contract(sellAbi, sellContract.address);

      let royaltyPercent:any = Number(formParam.royalty_percent) * 100;
      royaltyPercent = royaltyPercent.toFixed(0);

      console.log('royaltyPercent', royaltyPercent);

      let contractParams = {
        from: String(account),
        to: sellContract.address,
        
        data: depContract.methods
          .setRoyaltyDetails(
            formParam.contract_address,
            formParam.royalty_wallet,
           royaltyPercent
          )
          .encodeABI(),
      };

      web3.eth
        .estimateGas(contractParams)
        .then((gasEstimate) => {
          web3.eth
            .sendTransaction({ ...contractParams, gas: gasEstimate })
            .on("error", function (err: any) {
              toast.error("An unexpected error has occurred, please try again");
            })
            .on("receipt", function () {
              axios
                .post(API_URLS.CONTRACT_ROYALTY_SAVE, formParam)
                .then(function (res) {
                  reset();
                  toast.success(res?.data?.message);
                  successHandler && successHandler();
                })
                .catch((error) => {
                  toast.error(
                    error?.response?.data?.message?.message ||
                      "Please try again later"
                  );
                });
            })
            .on("transactionHash", function (txn: string) {
              console.log("txn", txn);
            });
        })
        .catch(() => {
          toast.error("Could not estimate gas, please check your balance");
        });
    }
    // setRoyaltyDetails(formParam.contract_address, formParam.royalty_wallet, formParam.royalty_percent)
  };
  {
    console.log("txnSellContract", txnSellContract);
  }
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="form" noValidate>
        <div className="form-row">
          <div className="form-group  col-md-12">
            <label className="form-label">Royalty Wallet</label>
            <Controller
              defaultValue=""
              control={control}
              name="royalty_wallet"
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <FormTextInput
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  inputRef={ref}
                  type={"text"}
                  error={errors.royalty_wallet}
                  placeholder="Enter wallet address"
                />
              )}
            />
          </div>

          <div className="form-group  col-md-12">
            <label className="form-label">Royalty Percent</label>
            <Controller
              defaultValue=""
              control={control}
              name="royalty_percent"
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <FormTextInput
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  inputRef={ref}
                  type={"text"}
                  error={errors.royalty_percent}
                  placeholder="Enter Discount Value"
                />
              )}
            />
          </div>
        </div>

        <div className="card-toolbar d-flex justify-content-end">
          <div className="btn-group">
            <button
              type="submit"
              className="btn btn-primary font-weight-bolder pull-right"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </>
  );
};
export default ManageRoyaltyForm;
