import { toHex } from "../utils";
import { CHAINS } from "./connectors";

export const switchNetwork = (network: string, library: any) => {
	return new Promise((resolve, reject) => {
		library.provider
			.request({
				method: "wallet_switchEthereumChain",
				params: [{ chainId: toHex(network || "") }],
			})
			.then(() => {
				resolve(true);
			})
			.catch((switchError: any) => {
				if (switchError.code === 4902) {
					let provider = library.provider;
					provider
						.request({
							method: "wallet_addEthereumChain",
							params: [CHAINS[network]],
						})
						.then(() => resolve(true))
						.catch(() => {
							reject(false);
						});
				} else {
					reject(false);
				}
			});
	});
};
