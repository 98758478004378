import React, { useState, useEffect, useRef } from "react";
import { API_URLS } from "../config";

import axios from "axios";
import "./listitem.css";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import Web3 from "web3";
import { useWeb3React } from "@web3-react/core";
import { toast } from "react-toastify";
import { switchNetwork } from "../web3/functions";

import moment from "moment";
import SetApprovalModal from "../components/contract/setApprovalModal";

export interface FetchHiddednContract {
  onlyHidden: boolean;
  singleData: boolean;
  chainId?: number;
  source: string;
}
export enum CurrentStatusName {
  RECEIPT_IN_PROGRESS = "Approve collection",
  TXN_IN_PROGRESS = "List Sale",
  TXN_COMPLETED = "Item listed for Sale",
}

export interface MakeItemProgress {
  currentStep: number;

  currentStatusName: CurrentStatusName;
}

const Listitem = () => {
  const defaultMakeItemProgress: MakeItemProgress = {
    currentStep: 0,

    currentStatusName: CurrentStatusName.RECEIPT_IN_PROGRESS,
  };
  const [toggleState, setToggleState] = useState(1);
  const { chainId, library } = useWeb3React();

  const { account } = useWeb3React();
  const [collections, setCollections] = useState<any>([]);
  const [salefees, setSalefees] = useState<any>([]);
  const { address, token } = useParams();
  const [sellContract, setSellContract] = useState<any>(null);
  const [genericContract, setGenericContract] = useState<any>(null);
  const [state, setState] = useState({
    start: moment(),
    end: moment(),
  });

  const dateRef: any = useRef();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [handleMakeItemProgress, setHhandleMakeItemProgress] =
    useState<MakeItemProgress>(defaultMakeItemProgress);

  useEffect(() => {
    if (chainId) {
      getSaleContractHandler(chainId);
      getGenericContractHandler();
    }
  }, [chainId]);

  useEffect(() => {
    fetchCardDetails();
    saleFeesDetails();
  }, [address, token]);

  const toggleTab = (index: any) => {
    setToggleState(index);
  };

  const getSaleContractHandler = async (chainId: number) => {
    let params: FetchHiddednContract = {
      onlyHidden: true,
      singleData: true,
      chainId,
      source: "sale-contract",
    };
    const getDetails = await fetchContracts(params);
    if (getDetails && getDetails.success) {
      setSellContract(getDetails);
    }
  };
  const getGenericContractHandler = async () => {
    let params: FetchHiddednContract = {
      onlyHidden: true,
      singleData: true,

      source: "generic-contract",
    };
    const getDetails = await fetchContracts(params);
    if (getDetails && getDetails.success) {
      setGenericContract(getDetails);
    }
  };

  const fetchContracts = (seatchParams: FetchHiddednContract) => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(API_URLS.GET_CONTRACTS, { params: { ...seatchParams } })
        .then(function (res) {
          resolve({
            success: res?.data?.data?.id ? true : false,
            ...res.data.data,
          });
        })
        .catch(function (error) {
          resolve({
            success: false,
          });
        });
    });
  };

  const fetchCardDetails = () => {
    let params: any = {
      // owner_address: account,
      token_id: token,
      contract_address: address,
    };
    axios
      .get(API_URLS.CRYPTOVERSE, { params })
      .then((res: any) => {
        // setCollections(res.data);
        console.log("res", res.data.data);
        setCollections(res.data.data);
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  const saleFeesDetails = () => {
    axios
      .get(API_URLS.GET_SETTINGS)
      .then((res: any) => {
        //   console.log("sale fess",res.data.data)
        setSalefees(res.data.data);
      })
      .catch((error: any) => {
        console.log(error);
      });
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    trigger,
  } = useForm();
  const fixedSubmit = async (data: any) => {
    try {
      let params: any = {};
      if (toggleState === 2) {
        params = {
          contract_address: collections.contract_address,
          token_id: collections.token_id,
          type: "auction",
          start_price: data.start_price,
          end_price: data.end_price,

          end_datetime: state.end.format("YYYY-MM-DD h:mm:ss"),
          start_datetime: state.start.format("YYYY-MM-DD h:mm:ss"),
        };
      } else {
        params = {
          contract_address: collections.contract_address,
          token_id: collections.token_id,
          type: "fixed",
          start_price: data.start_price,
        };
      }
      setApprovalHandler(params);

      //console.log('params', params);
    } catch (err) {
      console.log("error in fixedSubmit", err);
    }
  };

  const setApprovalHandler = async (formParams: any) => {
    try {
      if (!genericContract?.abi || !genericContract?.byte_code) {
        toast.error("Abi or bytecode is not set for genreric contract");
        return;
      }

      if (!sellContract?.address) {
        toast.error("Sell contract address required");
        return;
      }

      let genericAbiCode = JSON.parse(genericContract.abi);
      let genericByteCode = JSON.parse(genericContract.byte_code);

      const web3 = new Web3(library.provider);
      let currentChainId = await web3.eth.getChainId();
      let reqdChain = genericContract.chain_id;

      // if (currentChainId !== reqdChain) {
      //   switchNetwork(String(reqdChain), library).then(() =>
      //   setApprovalHandler(formParams)
      //   );
      //   return;
      // }

      let depContract = new web3.eth.Contract(genericAbiCode, genericByteCode);

      let contractParams = {
        from: String(account),
        to: address,
        // to: genericContract.address,
        data: depContract.methods
          .setApprovalForAll(sellContract.address, true)
          .encodeABI(),
      };
      web3.eth
        .estimateGas(contractParams)
        .then((gasEstimate) => {
          stepChangeHandler(1, CurrentStatusName.RECEIPT_IN_PROGRESS);
          setShowModal(true);
          web3.eth
            .sendTransaction({ ...contractParams, gas: gasEstimate })
            .on("receipt", function () {
              stepChangeHandler(2, CurrentStatusName.RECEIPT_IN_PROGRESS);
              makeItemHandler(formParams);
            })
            .on("error", () => {
              modalCloseHandler();
              toast.error("Some error occurred, please try again");
            });
        })
        .catch(() => {
          modalCloseHandler();
          toast.error("Could not estimate gas, please check your balance");
        });
    } catch (err) {
      setHhandleMakeItemProgress(defaultMakeItemProgress);
      console.log("error in makeItemHandler", err);
    }
  };

  const stepChangeHandler = (
    stepNumber: number,
    stepName: CurrentStatusName
  ) => {
    let tempProgress = { ...handleMakeItemProgress };
    tempProgress.currentStatusName = stepName;
    tempProgress.currentStep = stepNumber;
    setHhandleMakeItemProgress(tempProgress);
  };

  const makeItemHandler = async (formParams: any) => {
    try {
      if (!sellContract?.abi || !sellContract?.byte_code) {
        toast.error("Abi or bytecode is missing");
        return;
      }

      let sellAbi = JSON.parse(sellContract.abi);
      let sellByteCode = JSON.parse(sellContract.byte_code);

      const web3 = new Web3(library.provider);
      let currentChainId = await web3.eth.getChainId();
      let reqdChain = sellContract.chain_id;
      if (currentChainId !== reqdChain) {
        switchNetwork(String(reqdChain), library).then(() =>
          makeItemHandler(formParams)
        );
        return;
      }
      let depContract = new web3.eth.Contract(sellAbi,  sellContract.address);

      let contractParams = {
        from: String(account),
        to: sellContract.address,

        data: depContract.methods
          .makeItem(
            address,
            token,
            Web3.utils.toWei(String(formParams.start_price), "ether")
          )
          .encodeABI(),
      };

      web3.eth
        .estimateGas(contractParams)
        .then((gasEstimate) => {
          web3.eth
            .sendTransaction({ ...contractParams, gas: gasEstimate })
            .on("error", function (err: any) {
              toast.error("An unexpected error has occurred, please try again");
            })
            .on("receipt", function () {
              stepChangeHandler(4, CurrentStatusName.TXN_IN_PROGRESS);
              toast.success("Transaction saved  successfully");
            })
            .on("transactionHash", function (txn: string) {
              formParams["txn_hash"] = txn;
              formParams["chain_id"] = chainId;
              formParams["owner_address"] = account;
              axios
                .post(API_URLS.LIST_FOR_SALE, formParams)
                .then(function (res) {
                  stepChangeHandler(3, CurrentStatusName.TXN_COMPLETED);
                  console.log("post", res);
                  reset();
                  // toast.success(res?.data?.message);
                })
                .catch(function (error) {
                  toast.error(
                    error?.response?.data?.message || "Please try again later"
                  );
                });
            });
        })
        .catch(() => {
          modalCloseHandler();
          toast.error("Could not estimate gas, please check your balance");
        });
    } catch (err:any) {
      modalCloseHandler();
      toast.error(err?.message || "Please try again later");
      console.log("error in makeItemHandler", err);
    }
  };

  const handleCallback = (start: any, end: any) => {
    setState({ start, end });
  };
  const modalCloseHandler = () => {
    setShowModal(false);
    setHhandleMakeItemProgress(defaultMakeItemProgress);
  };

  return (
    <div>
      <SetApprovalModal
        isOpen={showModal}
        onClose={() => modalCloseHandler()}
        successHandler={() => {
          console.log(">>>");
        }}
        handleMakeItemProgress={handleMakeItemProgress}
      />
      <div id="header">
        <div className="container">
          <div className="row head">
            <div className="col-md-4">
              <span>{collections.contract_name}</span>
              <br />
              <span>{collections?.metadata?.name}</span>
            </div>
          </div>
        </div>
      </div>

      <section className="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="sale">
                <h3>List item for sale</h3>
              </div>

              <div className="type">
                <h6>Type</h6>

                <div className="btn-group">
                  <div className="bloc-tabs">
                    <button
                      className={
                        toggleState === 1 ? "tabs active-tabs" : "tabs"
                      }
                      onClick={() => toggleTab(1)}
                    >
                      Fixed Price
                    </button>
                    <button
                      className={
                        toggleState === 2 ? "tabs active-tabs" : "tabs"
                      }
                      onClick={() => toggleTab(2)}
                    >
                      Timed Auction
                    </button>
                  </div>
                </div>
              </div>

              <div className="content-tabs">
                {/* <div
                  className={
                    toggleState === 1 || toggleState === 2
                      ? "content  active-content"
                      : "content"
                  }
                >

                </div> */}

                <div
                  className={
                    toggleState === 1 || toggleState === 2
                      ? "content  active-content"
                      : "content"
                  }
                >
                  <form onSubmit={handleSubmit(fixedSubmit)}>
                    <div className="min-bid">
                      {toggleState === 2 ? (
                        <h6>Minimum Bid</h6>
                      ) : (
                        <h6>Price</h6>
                      )}

                      <div className="min-bid-info">
                        <select
                          name="cars"
                          id="cars"
                          className="select-tag"
                          disabled
                        >
                          <option value="volvo">Eth</option>
                          <option value="saab">Saab</option>
                          <option value="opel">Opel</option>
                          <option value="audi">Audi</option>
                        </select>
                        <span>
                          <input
                            type="text"
                            id="ip3"
                            placeholder="Amount"
                            className="form-control"
                            {...register("start_price", {
                              required: "Start Price is required",
                            })}
                          />
                        </span>
                      </div>

                      {toggleState === 2 ? (
                        <div>
                          <div className="duration">
                            <h6>Duration</h6>

                            <div className="duration-info">
                              <DateRangePicker
                                ref={dateRef}
                                initialSettings={{
                                  timePicker: true,
                                  locale: {
                                    format: "MMM DD, YYYY (hh:mm: A)",
                                  },
                                  startDate: state.start.toDate(),
                                  endDate: state.end.toDate(),
                                  //   ranges: dateRanges,
                                  drops: "up",
                                }}
                                onCallback={handleCallback}
                              >
                                <input
                                  type="text"
                                  className="form-control col-4"
                                />
                              </DateRangePicker>
                            </div>
                          </div>
                          <div className="res-bid">
                            <h6>Reserve Price</h6>
                            <div className="res-bid-info">
                              <select
                                name="cars"
                                id="cars"
                                className="select-tag"
                                disabled
                              >
                                <option value="volvo">Eth</option>
                                <option value="saab">Saab</option>
                                <option value="opel">Opel</option>
                                <option value="audi">Audi</option>
                              </select>
                              <span>
                                <input
                                  type="text"
                                  id="ip4"
                                  placeholder="Amount"
                                  className="form-control"
                                  {...register("end_price", {
                                    required: "End Price is required",
                                  })}
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="fees">
                        {salefees.map((elem: any) => {
                          //   console.log(elem)
                          if (elem.key === "sale_service_fee") {
                            return <p>Service Fees: {elem?.value} %</p>;
                          }
                        })}
                      </div>

                      <div className="listing">
                        <button className="complete">Complete Listing</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="sale">
                <h3>Preview</h3>
              </div>

              <div className="profile">
                <div className="card-profile">
                  <img
                    src={collections?.metadata?.image || "/no-image.jpeg"}
                    className="avatar"
                    alt="Avatar"
                    style={{ width: "100%" }}
                  />
                  <div className="container-profile">
                    <div className="det1">
                      <h4>
                        <b>{collections?.contract_name}</b>
                      </h4>
                      <p>{collections?.metadata?.name}</p>
                    </div>
                    <div className="det2">
                      <h4>
                        <b>Price</b>
                      </h4>
                      <p>0 </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Listitem;
