import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { mintPremintSelected } from "../../containers/deployPage";
import MintPremintForm from "./mintPreMintForm";

interface MintPreMintModalProps {
  isOpen: boolean;
  onClose: () => void;

  mint: (index: number, amount: number) => any;
  premint?: (index: number, amount: number) => any;
  indVal: mintPremintSelected;
}

const MintPreMintModal = ({
  isOpen,
  onClose,
  mint,
  indVal,
  premint,
}: MintPreMintModalProps) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <Modal size={"sm"} centered onHide={onClose} show={isOpen}>
      <Modal.Header closeButton> </Modal.Header>
      <Modal.Body>
        <MintPremintForm
          mint={mint}
          premint={premint}
          onClose={onClose}
          indVal={indVal}
        />
      </Modal.Body>
    </Modal>
  );
};

export default MintPreMintModal;
