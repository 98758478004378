import React, { useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { API_URLS } from "../config";
import Web3 from "web3";
import { useWeb3React } from "@web3-react/core";
import { toast } from "react-toastify";
interface props {
  amount: string;
}
const Sellpayment = ({ amount }: props) => {
  const { chainId, account } = useWeb3React();
  const [paymentid, setPaymentId] = useState<any>("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    trigger,
  } = useForm();

  useEffect(() => {
    if (account) {
      fetchPaymentMethod(account);
    }
  }, [account]);
  const fetchPaymentMethod = (walletAddress: any) => {
    let params = {
      walletAddress,
    };

    axios
      .get(API_URLS.MTOKEN, { params })
      .then((res) => {
        console.log("res", res.data.data);
        console.log("id", res.data.data.id);
        console.log("pay", res.data.data.method_id);
        setPaymentId(res.data.data.method_id);
      })
      .catch((error) => {});
  };
  const onSubmit = (data: any) => {
    let params = {
      message: data.message,
      mToken: amount,
      walletAddress: account,
      paymentMethodId: paymentid,
    };

    axios
      .post(API_URLS.WYRE_TRANSFER, params)
      .then(function (res) {
        reset();
        toast.success(res?.data?.message || "Payment done");
      })
      .catch(function (error) {
        toast.error(error?.response?.data?.message || "Please try again later");
      });
  };
  return (
    <div className="container pt-5">
      <div className="row justify-content-sm-center pt-5">
        <div className="col-sm-6 shadow round pb-3">
          <h1 className="text-center pt-3 text-secondary">Sell Fiat Form</h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
              <label className="col-form-label">Message:</label>
              <textarea
                className={`form-control ${errors.message && "invalid"}`}
                {...register("message", { required: "Message is Required" })}
                onKeyUp={() => {
                  trigger("message");
                }}
              ></textarea>
              {errors.message && (
                <small className="text-danger">{errors.message.message}</small>
              )}
            </div>

            <input
              type="submit"
              className="btn btn-primary my-3"
              value="Sell"
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default Sellpayment;
