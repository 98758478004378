import { createReducer, createSlice } from "@reduxjs/toolkit";

export const tableSlice: any = createSlice({
  name: "table",
  initialState: {
    data: [],
  },

  reducers: {
    setData: (state: any, action) => {
      console.log(action);
      let temp = [...state.data];
      temp.unshift(action.payload);
	  state.data = temp;

      if (temp.length > 5) {
        temp.pop();

        state.data = temp;
      }
      console.log("temp",temp);
    },
  },
});

export const { setData } = tableSlice.actions;
export default tableSlice.reducer;
