import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./containers/home";
import { useWeb3React } from "@web3-react/core";
import { useEffect } from "react";
import { connectors, WalletNames } from "./web3/connectors";
import { changeModalSize } from "./common/functions";
import Settings from "./containers/settings";
import MToken from "./containers/mToken";
import axios from "axios";
import { API_URLS } from "./config";
import { useDispatch } from "react-redux";
import {
  setRates,
  setSettings,
  setUsers,
} from "./redux/reducers/common.reducer";
import Navbar from "./components/Navbar/navbar";
import { ToastContainer } from "react-toastify";
import DeployPage from "./containers/deployPage";
import MintPage from "./containers/mintPage";
import Mynft from "./containers/mynft";
import Sellfiat from "./containers/sellfiat";
import Cryptoverse from "./containers/cryptoverse";
import Listitem from "./containers/listitem";
import MyContractPage from "./containers/myContractPage";
import SellListPage from "./containers/sellListPage";
import SellFiatPayment from "./containers/sellfiatpayment";

function App() {
  const dispatch = useDispatch();
  const { activate, account } = useWeb3React();

  useEffect(() => {
    const provider = localStorage.getItem("provider");
    if (provider) activate(connectors[provider]);
    if (provider === WalletNames.WALLET_CONNECT) {
      changeModalSize();
    }

    axios
      .get(API_URLS.GET_SETTINGS)
      .then((res: any) => {
        dispatch(setSettings(res.data.data));
      })
      .catch(() => {});
    axios
      .get(API_URLS.GET_RATES)
      .then((res) => {
        dispatch(setRates(res.data.data));
      })
      .catch(() => {});
  }, []);
  useEffect(() => {
    if (account) {
      axios
        .get(API_URLS.GET_USER_DETAILS, { params: { walletAddress: account } })
        .then((res) => {
          dispatch(setUsers(res.data.data));
        })
        .catch(() => {});
    }
  }, [account]);

  return (
    <BrowserRouter>
      <>
        <Navbar />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/mtoken" element={<MToken />} />

          <Route path="/deploy" element={<DeployPage />} />
          <Route path="/mint" element={<MintPage />} />
          <Route path="/mynft" element={<Mynft />} />
          {/* <Route path="/sellfiat" element={<Sellfiat />} /> */}
          <Route path="/sellfiat" element={<SellFiatPayment />} />

          <Route
            path={"/mynft-details/:address/:token"}
            element={<Cryptoverse />}
          />

          <Route path="/listitem/:address/:token" element={<Listitem />} />
          <Route path="/my-contracts" element={<MyContractPage />} />

          <Route path="/sell-list" element={<SellListPage />} />
        </Routes>

        <ToastContainer />
      </>
    </BrowserRouter>
  );
}

export default App;
