import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import TxnTable from "./txnTable";

interface TxnTableModalProps {
  tokenId?: number | null;
  contractId?: number | null;
  isOpen: boolean;
  onClose: () => void;
}
const TxnTableModal = ({
  tokenId,
  contractId,
  isOpen,
  onClose,
}: TxnTableModalProps) => {
  return (
    <>
      <Modal size={"lg"} centered onHide={onClose} show={isOpen}>
        <Modal.Header closeButton> Transaction History </Modal.Header>
        <Modal.Body className="d-flex justify-content-center flex-column">
        <div className="container">
          <TxnTable tokenId={tokenId} contractId={contractId} />

          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default TxnTableModal;
