import React, { useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { API_URLS } from "../config";
import Web3 from "web3";
import { useWeb3React } from "@web3-react/core";
import { toast } from "react-toastify";
interface props {
  amount: string;
}
const Buypayment = ({ amount }: props) => {
  const { chainId, account } = useWeb3React();
  

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    trigger,
  } = useForm();
  const onSubmit = (data: any) => {
    let params = {
      ...data,
      chainId,
      mToken: amount,
      walletAddress: account,
    };
    console.log("data", data);
    axios
      .post(API_URLS.BUY_FIAT, params)
      .then(function (res) {
        console.log(res);

        toast.success(res?.data?.message || "Payment done");
      })
      .catch(function (error) {
        toast.error(error?.response?.data?.message?.message || "Please try again later");
        console.log("error",error);
      });
  reset();

  };
  console.log("errors", errors);
  return (
    <div className="container pt-5">
      <div className="row justify-content-sm-center pt-5">
        <div className="col-sm-6 shadow round pb-3">
          <h1 className="text-center pt-3 text-secondary">Buy Fiat Form</h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
              <label className="col-form-label">Card Number:</label>
              <input
                type="text" 
                className={`form-control`}
                {...register("cardNumber", {
                  required: "Card Number is Required",
                  minLength: {
                    value: 16,
                    message: "Minimum Required length is 16",
                  },
                  maxLength: {
                    value: 16,
                    message: "Maximum allowed length is 16 ",
                  },
                })}
              />

              {errors.cardNumber && (
                <small className="text-danger">
                  {errors.cardNumber.message}
                </small>
              )}
            </div>
            <div className="form-group">
              <label className="col-form-label">Expiry Month:</label>
              <input
                type="text"
                className={`form-control`}
                {...register("expMonth", {
                  required: "Expiry Month is Required",
                })}
              />

              {errors.expMonth && (
                <small className="text-danger">{errors.expMonth.message}</small>
              )}
            </div>
            <div className="form-group">
              <label className="col-form-label">Expiry Year:</label>
              <input
                type="text"
                className={`form-control`}
                {...register("expYear", {
                  required: "Expiry Year is Required",
                })}
              />

              {errors.expYear && (
                <small className="text-danger">{errors.expYear.message}</small>
              )}
            </div>
            <div className="form-group">
              <label className="col-form-label">CVV:</label>
              <input
                type="text"
                className={`form-control`}
                {...register("cvv", { required: "CVV is Required" })}
              />

              {errors.cvv && (
                <small className="text-danger">{errors.cvv.message}</small>
              )}
            </div>
            <div className="form-group">
              <label className="col-form-label">Street:</label>
              <input
                type="text"
                className={`form-control`}
                {...register("street1", { required: "Street is Required" })}
              />

              {errors.street1 && (
                <small className="text-danger">{errors.street1.message}</small>
              )}
            </div>
            <div className="form-group">
              <label className="col-form-label">City:</label>
              <input
                type="text"
                className={`form-control`}
                {...register("city", { required: "City is Required" })}
              />

              {errors.city && (
                <small className="text-danger">{errors.city.message}</small>
              )}
            </div>
            <div className="form-group">
              <label className="col-form-label">State:</label>
              <input
                type="text"
                className={`form-control`}
                {...register("state", { required: "State is Required" })}
              />

              {errors.state && (
                <small className="text-danger">{errors.state.message}</small>
              )}
            </div>
            <div className="form-group">
              <label className="col-form-label">Postal Code:</label>
              <input
                type="text"
                className={`form-control`}
                {...register("postalCode", {
                  required: "Postal Code is Required",
                })}
              />

              {errors.postalCode && (
                <small className="text-danger">
                  {errors.postalCode.message}
                </small>
              )}
            </div>

            <div className="form-group">
              <label className="col-form-label">Given Name</label>
              <input
                type="text"
                className={`form-control`}
                {...register("givenName", { required: "Name is Required" })}
              />

              {errors.givenName && (
                <small className="text-danger">
                  {errors.givenName.message}
                </small>
              )}
            </div>
            <div className="form-group">
              <label className="col-form-label">Family Name</label>
              <input
                type="text"
                className={`form-control`}
                {...register("familyName", {
                  required: " Family Name is Required",
                })}
              />

              {errors.familyName && (
                <small className="text-danger">
                  {errors.familyName.message}
                </small>
              )}
            </div>
            <div className="form-group">
              <label className="col-form-label">Email:</label>
              <input
                type="text"
                className={`form-control ${errors.email && "invalid"}`}
                {...register("email", {
                  required: "Email is Required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },
                })}
              />
              {errors.email && (
                <small className="text-danger">{errors.email.message}</small>
              )}
            </div>

            <div className="form-group">
              <label className="col-form-label">Phone:</label>
              <input
                type="text"
                className={`form-control ${errors.phone && "invalid"}`}
                {...register("phone", {
                  required: "Phone is Required",
                  pattern: {
                    value:
                      /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
                    message: "Invalid phone no",
                  },
                })}
                onKeyUp={() => {
                  trigger("phone");
                }}
              />
              {errors.phone && (
                <small className="text-danger">{errors.phone.message}</small>
              )}
            </div>

            <input
              type="submit"
              className="btn btn-primary my-3"
              value="Buy"
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default Buypayment;
