export const API_BASE_URL =
  process.env.REACT_APP_STAGE === "development"
    ? "http://localhost:4000"
    : "https://mm-api.dreamztesting.com";

export const API_URLS = {
  GET_USER_DETAILS: `${API_BASE_URL}/user`,
  GET_RATES: `${API_BASE_URL}/common/cryptoconverter`,
  GET_SETTINGS: `${API_BASE_URL}/site-settings`,
  UPDATE_SETTINGS: `${API_BASE_URL}/site-settings`,
  MAKE_PAYMENT: `${API_BASE_URL}/payment`,
  GET_ABI_AND_BYTECODE: `${API_BASE_URL}/contract/generate`,
  SAVE_CONTRACT: `${API_BASE_URL}/contract`,
  GET_CONTRACTS: `${API_BASE_URL}/contract`,
  MINT_NFT: `${API_BASE_URL}/nfts/`,
  TRANSACTION_SAVE: `${API_BASE_URL}/transactions`,
  TRANSACTION_LIST: `${API_BASE_URL}/transactions`,
  SYNC_TRANSACTIONS: `${API_BASE_URL}/transactions/wallet-txn-details`,
  OWNER_COLLECTIONS: `${API_BASE_URL}/nfts/mycontracts`,
  BUY_FIAT: `${API_BASE_URL}/transactions/wyre/reserve-and-make-payment`,
  SELL_FIAT: `${API_BASE_URL}/transactions/wyre/create-payment-method`,
  PAYMENT_IMAGE: `${API_BASE_URL}/transactions/wyre/upload-payment-method-doc`,
  MTOKEN: `${API_BASE_URL}/user/payment-methods`,
  USER_BUY_WITH_FIAT_LIST: `${API_BASE_URL}/user/orders`,
  USER_SELL_WITH_FIAT_LIST: `${API_BASE_URL}/user/transfers`,
  WYRE_TRANSFER: `${API_BASE_URL}/transactions/wyre/transfer`,
  CRYPTOVERSE: `${API_BASE_URL}/nfts/details-by-contract-token?`,
  LIST_FOR_SALE: `${API_BASE_URL}/listing`,
  CONTRACT_ROYALTY_SAVE: `${API_BASE_URL}/contract/upsert-royalty`,
  CONTRACT_UPDATE: `${API_BASE_URL}/contract`,
  MTOKEN_BUY_ONBOARDING: `${API_BASE_URL}/transactions/wyre/mtoken-buy-onboarding`,
  SAVE_ORDER_TXN: `${API_BASE_URL}/transactions/wyre/order-save`,
  GET_SELL_LISTING: `${API_BASE_URL}/listing`,
  SAVE_BUY_LISTING: `${API_BASE_URL}/listing/buy-listing`,
  NFT_OWNER_ADDRESS_UPDATE: `${API_BASE_URL}/nfts/owner-address-update`,
  WYRE_CREATE_USER: `${API_BASE_URL}/transactions/wyre/create-user`,
  CREATE_PAYMENT_METHOD_USING_PLIAD: `${API_BASE_URL}/transactions/wyre/create-payment-method-using-plaid`,
  GET_WYRE_USER_ONBOARDING_URL: `${API_BASE_URL}/transactions/wyre/user-onboarding-url`,
  UPDATE_NFT_META_DATA: `${API_BASE_URL}/nfts/update-metadata`,
};
