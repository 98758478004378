import React from "react";
import "./navbar.css";
// import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { Link, NavLink } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";

const Navbar = () => {
  const { active } = useWeb3React();
  return (
    <div>
      <nav className="navbar navbar-expand-lg ">
        <a className="navbar-brand logo" style={{ color: "white" }}>
          Minter's Market
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div
          className="collapse navbar-collapse justify-content-center"
          id="navbarSupportedContent"
        >
          <ul className="nav">
            <li className="nav-item">
              <Link className="nav-link linktag" to="/">
                Home
              </Link>
            </li>

            <li className="nav-item">
              <Link className="nav-link linktag" to="/settings">
                Settings
              </Link>
            </li>

            <li>
              <Link className="nav-link linktag" to="/mtoken">
                mToken
              </Link>
            </li>
            <li>
              <Link className="nav-link linktag" to="/deploy">
                Deploy
              </Link>
            </li>
            <li>
              <Link className="nav-link linktag" to="/mynft">
                My NFTs
              </Link>
            </li>
            <li>
              <Link className="nav-link linktag" to="/my-contracts">
                My Contracts
              </Link>
            </li>

            <li>
              <Link className="nav-link linktag" to="/sell-list">
              Marketplace
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
