import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { ETH_CHAINS, AVAX_CHAINS, MATIC_CHAINS } from "../web3/connectors";

export function useCommonSelector() {
  const common = useSelector((state: RootState) => state.common);
  return common;
}

export function useSettingsSelector() {
  const settings = useSelector((state: RootState) => state.common.settings);
  let findValue = (key: string): any => {
    return settings.find((setting: any) => setting.key === key);
  };
  return findValue;
}

export function useRateSelector() {
  const rates = useSelector((state: RootState) => state.common.rates);
  return rates;
}

export function useUserSelector() {
  const users = useSelector((state: RootState) => state.common.users);
  return users;
}

export function useRateUSD() {
  const rates = useSelector((state: RootState) => state.common.rates);

  let converter = (id: number) => {
    if (ETH_CHAINS.includes(id)) {
      return rates.ETH.USD;
    } else if (AVAX_CHAINS.includes(id)) {
      return rates.AVAX.USD;
    } else if (MATIC_CHAINS.includes(id)) {
      return rates.MATIC.USD;
    }
  };
  return converter;
}
