import React, { useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import SaleList from "../components/sell/saleList";

const SellListPage = () => {
  const { account } = useWeb3React();
  const ethereum = (window as any).ethereum;
  const { chainId, library } = useWeb3React();

  return (
    <div className="container">
      <div className=" mb-4 mt-5">
        <h5>Marketplace</h5>
        {account ? <SaleList ownerAddress={account} /> : null}
      </div>
    </div>
  );
};

export default SellListPage;
