import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import Web3 from "web3";
import { useWeb3React } from "@web3-react/core";
import { switchNetwork } from "../web3/functions";
import { toast } from "react-toastify";
import {
  useCommonSelector,
  useRateUSD,
  useSettingsSelector,
} from "../hooks/selectors";
import {
  AVAX_CHAINS,
  CHAIN_OPTS,
  ETH_CHAINS,
  MATIC_CHAINS,
  WalletNames,
} from "../web3/connectors";
import Select from "react-select";
import { setData } from "../redux/reducers/table.reducer";

import { API_URLS } from "../config";
const MintPage = () => {
  const { account } = useWeb3React();

  const ethereum = (window as any).ethereum;

  const common = useCommonSelector();
  const { chainId, library } = useWeb3React();

  const findValue = useSettingsSelector();

  const dispatch = useDispatch();
  const rateFn = useRateUSD();

  const [chain, setChain] = useState<any>("");
  const [rate, setRate] = useState(1);

  useEffect(() => {
    setRate(rateFn(chain.value));
  }, [chain]);

  return (
    <div className="container">
      
    </div>
  );
};

export default MintPage;
