import { useWeb3React } from "@web3-react/core";
import { useState } from "react";
import SelectWalletModal from "../components/modal";
import React from "react";
import { switchNetwork } from "../web3/functions";
import axios from "axios";
import { API_BASE_URL } from "../config";
import { toast } from "react-toastify";

const Home = () => {
	const { active, account, library, chainId, deactivate } = useWeb3React();

	const [showModal, setShowModal] = useState<boolean>(false);
	const [message, setMessage] = useState<string>("");

	const disconnectWallet = () => {
		deactivate();
		toast.success("disconnect successful");
		localStorage.removeItem("provider");
	};

	const fetchNonceVerify = () => {
		axios
			.put(API_BASE_URL + "/nounce/gen-nounce", {
				wallet: account,
				address: "Ambient",
			})
			.then((resp: any) => {
				library
					.getSigner(account)
					.signMessage(resp.data.data.message)
					.then((signature: string) => {
						axios
							.post(API_BASE_URL + "/nounce/verify", {
								signature,
								address: account,
							})
							.then((res) => {
								toast.success("Sign Successful");
								setMessage('Signed on :'+ new Date())
							})
							.catch((err) => {
								toast.error("Some error occurred, please try again");
							});
					});
			})
			.catch((err: any) => {
				console.log(err)
				toast.error("Some error occurred, please try again");
			});
	};
		// {console.log(localStorage.getItem("provider"))}
		console.log(account)

	return (

		<div className="h-100 w-100 d-flex justify-content-center flex-column align-items-center">



			{active ? (
				<>
					<button className="btn btn-danger" onClick={disconnectWallet}>
						Disconnect
					</button>
					<button className="btn btn-warning mt-2" onClick={fetchNonceVerify}>
						Sign
					</button>
				</>
			) : (
				<button className="btn btn-primary" onClick={() => setShowModal(true)}>
					Connect
				</button>
			)}
			<h6 className="mt-5">Status: {active ? "success" : ""}</h6>
			<h6>Wallet Address: {account || ""} </h6>
	
			<h6>Network ID: {chainId}</h6>
			<h6>Wallet Type: {localStorage.getItem("provider")}</h6>
			{message}
			
			<select

				onChange={(e) => switchNetwork(e.target.value, library)}
				value={String(chainId)}
			>

				<option value="1">Mainnet</option>
				<option value="3">Ropsten</option>
				<option value="4">Rinkeby</option>
				<option value="5">Goerli</option>
				<option value="42">Kovan</option>
				<option value="137">Matic Mainnet</option>
				<option value="80001">Matic Testnet</option>
				<option value="43113">Avalanche Testnet</option>
				<option value="43114">Avalanche Mainnet</option>
			</select>

			<SelectWalletModal
				isOpen={showModal}
				onClose={() => setShowModal(false)}
			/>
		</div>
	);
};

export default Home;
