import axios from "axios";
import React, { useEffect, useState, forwardRef, useRef, useImperativeHandle } from "react";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";

import { API_URLS } from "../../config";
import Web3 from "web3";
import { useWeb3React } from "@web3-react/core";

const UserOrderList =forwardRef((props, ref) => {
  const { chainId, account } = useWeb3React();

  const [tableData, setData] = useState<any>({});
  const [itemsPerPage, setItemsPerPage] = useState<any>(10);
  const [activePage, setActivePage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(0);

  useEffect(() => {
    if (account) {
      let initialPage = 1;
      setActivePage(initialPage);
      loadInitialData(initialPage);
    }
  }, [account]);


  useImperativeHandle(ref, () => ({

    resetDataHandler() {
      let initialPage = 1;
      setActivePage(initialPage);
      loadInitialData(initialPage);
    }

  }));

  const loadInitialData = (page: number) => {
    let params: any = {
      page,
      perPage: itemsPerPage,
      walletAddress: account,
    };
    axios
      .get(API_URLS.USER_BUY_WITH_FIAT_LIST, { params })
      .then((res) => {
        setPageCount(res.data.data.totalPage);
        setData(res.data.data);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  const handlePageClick = async (data: any) => {
    let currentPage = data.selected + 1;
    setActivePage(currentPage);
    loadInitialData(currentPage);
  };

  return (
    <>
      <div className="container mt-4">
        <h5 className="text-secondary">Buy with Fiat List</h5>
        <table className="table w-100">
          <thead className="thead-dark">
            <tr>
              <th>Order ID</th>

              <th>Source Amount</th>

              <th>Purchase Amount</th>
              <th>Source Currency </th>

              <th>Dest Currency </th>
              <th>Dest </th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {tableData?.data?.length ? (
              tableData.data.map((row: any, index: number) => (
                <tr key={row.id}>
                  <td> {row.order_id} </td>
                  <td>{row.source_amount ? row.source_amount.toFixed(6) : 0} </td>
                 
                  <td> {row.purchase_amount ? row.purchase_amount.toFixed(6) : 0} </td>

                  <td>{row.source_currency} </td>
                  <td>{row.dest_currency} </td>
                  <td>{row.dest} </td>
                  <td>{row.status} </td>
                </tr>
              ))
            ) : (
              <tr>
                <td>No Data found</td>
              </tr>
            )}
          </tbody>
        </table>

        <ReactPaginate
          previousLabel={"previous"}
          nextLabel={"next"}
          breakLabel={"..."}
          pageCount={pageCount}
          pageRangeDisplayed={itemsPerPage}
          onPageChange={handlePageClick}
          containerClassName={"pagination justify-content-center"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"}
        />
      </div>
    </>
  );
});

export default UserOrderList;
