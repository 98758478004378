import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import {
  copyText,
  sliceString,
  capitalizeFirstLetter,
  copyTransaction,
  copyFromAddress,
} from "../../common/functions";
import { API_URLS } from "../../config";
import Web3 from "web3";
import { useWeb3React } from "@web3-react/core";
import { switchNetwork } from "../../web3/functions";
import { Modal } from "react-bootstrap";
import { DEPLOY_CHAIN_OPTS } from "../../web3/connectors";

interface TxnTableProps {
  tokenId?: number | null;
  contractId?: number | null;
}

const TxnTable = ({ tokenId, contractId }: TxnTableProps) => {
  const [tableData, setData] = useState<any>({});
  const [itemsPerPage, setItemsPerPage] = useState<any>(10);
  const [activePage, setActivePage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(0);

  useEffect(() => {
    let initialPage = 1;
    setActivePage(initialPage);
    loadInitialData(initialPage);
  }, [tokenId, contractId]);

  const loadInitialData = (page: number) => {
    let params: any = {
      page,
      perPage: itemsPerPage,
      token_id: tokenId,
      contract_id: contractId ? contractId : null,
    };
    axios
      .get(API_URLS.TRANSACTION_LIST, { params })
      .then((res) => {
        setPageCount(res.data.data.totalPage);
        setData(res.data.data);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  const handlePageClick = async (data: any) => {
    let currentPage = data.selected + 1;
    setActivePage(currentPage);
    loadInitialData(currentPage);
  };

  return (
    <>
      <table className="table w-100">
        <thead className="thead-dark">
          <tr>
            <th>Txn Hash</th>
            <th>Type</th>
            <th>Chain Name</th>

            <th>Contract Address</th>
            <th>Price</th>

            <th>From </th>
            <th>To </th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {tableData?.data?.length ? (
            tableData.data.map((row: any, index: number) => (
              <tr key={row.id}>
                <td
                  title={row.txn_hash}
                  onClick={() => copyTransaction(row.txn_hash, row.chain_id)}
                >
                  {sliceString(row.txn_hash)}
                </td>

                <td>{capitalizeFirstLetter(row.type)}</td>

                <td>
                  {
                    DEPLOY_CHAIN_OPTS.find(
                      (opt: any) => opt.value === row.chain_id
                    )?.label
                  }
                </td>

                <td
                  onClick={() =>
                    row.contract_address &&
                    copyFromAddress(row.from_address, row.chain_id)
                  }
                >
                  {sliceString(row.contract_address)}
                </td>
                
                <td>{(!isNaN(row.price)) ? row.price.toFixed(6)  : 'N/A'}</td>

                <td
                  title={row.from_address}
                  onClick={() => copyText(row.from_address)}
                >
                  {sliceString(row.from_address)}
                </td>

                <td
                  title={row.to_address}
                  onClick={() => copyText(row.to_address)}
                >
                  {sliceString(row.to_address)}
                </td>

                <td>{capitalizeFirstLetter(row.status)}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td>No transaction found</td>
            </tr>
          )}
        </tbody>
      </table>

      <ReactPaginate
        previousLabel={"previous"}
        nextLabel={"next"}
        breakLabel={"..."}
        pageCount={pageCount}
        // marginPagesDisplayed={2}
        pageRangeDisplayed={itemsPerPage}
        onPageChange={handlePageClick}
        containerClassName={"pagination justify-content-center"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        breakClassName={"page-item"}
        breakLinkClassName={"page-link"}
        activeClassName={"active"}
      />
    </>
  );
};

export default TxnTable;
