import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useWeb3React } from "@web3-react/core";
import { mintPremintSelected } from "../../containers/deployPage";

interface MintPremintFormProps {
  mint: (index: number, amount: number) => any;
  indVal: mintPremintSelected;
  premint?: (index: number, amount: number) => any;
  onClose?: () => void;
}
const MintPremintForm = ({
  mint,
  indVal,
  premint,
  onClose,
}: MintPremintFormProps) => {
  const { chainId, account } = useWeb3React();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    trigger,
  } = useForm();
  const onSubmit = (data: any) => {
    if (indVal.type === "mint") {
      mint && mint(indVal.index, data.amount);
    } else if (indVal.type === "premint") {
      premint && premint(indVal.index, data.amount);
    }
    onClose && onClose();
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-group">
        <label className="col-form-label">Enter Amout</label>
        <input
          type="text"
          className={`form-control`}
          {...register("amount", {
            required: "Amount is Required",
          })}
        />
      </div>
      <input type="submit" className="btn btn-primary my-3" value="Proceed" />
    </form>
  );
};

export default MintPremintForm;
