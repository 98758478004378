import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";

const INFURA_KEY = "ad027482b9d04724b41c81f4ae1ecce2";
export const ETH_CHAINS = [1, 3, 4, 5, 42];
export const AVAX_CHAINS = [43113, 43114];
export const MATIC_CHAINS = [137, 80001];

const supportedChainIds = [...ETH_CHAINS, ...AVAX_CHAINS, ...MATIC_CHAINS];

const injected = new InjectedConnector({
  supportedChainIds,
});

const rpcUrl = `https://mainnet.infura.io/v3/${INFURA_KEY}`;

const walletconnect = new WalletConnectConnector({
  qrcode: true,
  supportedChainIds,
  infuraId: INFURA_KEY,
  bridge: "https://bridge.walletconnect.org",
});

const walletlink = new WalletLinkConnector({
  url: rpcUrl,
  appName: "web3-react-demo",
  supportedChainIds,
});

interface ConnectorType {
  [name: string]: any;
}

export const connectors: ConnectorType = {
  injected: injected,
  walletConnect: walletconnect,
  coinbaseWallet: walletlink,
};

export enum WalletNames {
  INJECTED = "injected",
  WALLET_CONNECT = "walletConnect",
  COINBASE = "coinbaseWallet",
}

export const CHAINS: any = {
  // Polygon
  137: {
    chainId: "0x89",
    chainName: "Matic Mainnet",
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18,
    },
    rpcUrls: ["https://polygon-rpc.com/"],
    blockExplorerUrls: ["https://polygonscan.com/"],
  },
  80001: {
    chainId: "0x13881",
    chainName: "Polygon Testnet",
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18,
    },
    rpcUrls: ["https://rpc-mumbai.maticvigil.com/"],
    blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
  },
  //Avalanche
  43113: {
    chainId: "0xA869",
    chainName: "Avalanche Testnet",
    nativeCurrency: {
      name: "AVAX",
      symbol: "AVAX",
      decimals: 18,
    },
    rpcUrls: ["https://api.avax-test.network/ext/bc/C/rpc"],
    blockExplorerUrls: ["https://testnet.snowtrace.io"],
  },
  43114: {
    chainId: "0xA86A",
    chainName: "Avalanche Mainnet",
    nativeCurrency: {
      name: "AVAX",
      symbol: "AVAX",
      decimals: 18,
    },
    rpcUrls: ["https://api.avax.network/ext/bc/C/rpc"],
    blockExplorerUrls: ["https://snowtrace.io"],
  },
};

// define the list of Networks allowed with their chainID (value) and Symbol
export const CHAIN_OPTS: any = [
  { label: "Ethereum - Mainnet", value: 1, unit: "ETH"},
  { label: "Ethereum - Ropsten", value: 3, unit: "ETH" },
  { label: "Ethereum - Rinkeby", value: 4, unit: "ETH" },
  { label: "Ethereum - Goerli", value: 5, unit: "ETH" },
  { label: "Ethereum - Kovan", value: 42, unit: "ETH" },
  { label: "Avalanche - Testnet", value: 43113, unit: "AVAX" },
  { label: "Avalanche - Mainnet", value: 43114, unit: "AVAX" },
  { label: "Matic - Mainnet", value: 137, unit: "MATIC" },
  { label: "Matic - Testnet", value: 80001, unit: "MATIC" },
];

// define the list of Networks allowed with their chainID (value) and Symbol for deployment
export const DEPLOY_CHAIN_OPTS: any = [
  { label: "Ethereum - Mainnet", value: 1, unit: "ETH" },

  { label: "Ethereum - Rinkeby", value: 4, unit: "ETH" },

  { label: "Avalanche - Testnet", value: 43113, unit: "AVAX" },
  { label: "Avalanche - Mainnet", value: 43114, unit: "AVAX" },
  { label: "Matic - Mainnet", value: 137, unit: "MATIC" },
  { label: "Matic - Testnet", value: 80001, unit: "MATIC" },
];
