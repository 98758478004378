import axios from "axios";
import React, { useEffect, useState } from "react";
import { API_URLS } from "../config";
import { toast } from "react-toastify";

import "./cryptoverse.css";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Accordion } from "react-bootstrap";
import { Dropdown } from "react-bootstrap";
import { Table } from "react-bootstrap";
import Web3 from "web3";
import { useWeb3React } from "@web3-react/core";
import { Link } from "react-router-dom";
import { FetchHiddednContract } from "./listitem";
import {
  getFileUrlFromMetaData,
  getPercentageAmount,
} from "../common/functions";
import { useSettingsSelector } from "../hooks/selectors";
import { switchNetwork } from "../web3/functions";
import { DEPLOY_CHAIN_OPTS } from "../web3/connectors";
import DisplayFile from "../components/DisplayFile";
import UpdateMetaDataBtn from "../components/nft/UpdateMetaDataBtn";

interface UpdateOwnerAddress {
  tokenId: number;
  contractAddress: string;
  ownerAddress: string;
}

const Cryptoverse = () => {
  const { account } = useWeb3React();

  const Navigate = useNavigate();
  const [collections, setCollections] = useState<any>([]);
  const [sellContract, setSellContract] = useState<any>(null);
  const [genericContract, setGenericContract] = useState<any>(null);
  const { address, token } = useParams();
  const { chainId, library } = useWeb3React();
  const findValue = useSettingsSelector();
  const [nftOwner, setNftOwner] = useState<any>(null);

  console.log("nftOwne", nftOwner);
  useEffect(() => {
    if (
      nftOwner &&
      collections.id &&
      collections.owner_address &&
      nftOwner !== collections.owner_address.toLowerCase()
    ) {
      updateTheOwnerAddressHandler({
        tokenId: Number(token),
        contractAddress: collections.contract_address,
        ownerAddress: nftOwner,
      });
      console.log("Not the owner");
    }
  }, [nftOwner, collections?.id]);

  useEffect(() => {
    if (
      !isNaN(Number(token)) &&
      genericContract?.abi &&
      library &&
      library.provider &&
      sellContract?.id &&
      collections?.id
    ) {
      try {
        const web3 = new Web3(library.provider);
        let genericAbi = JSON.parse(genericContract.abi);
        let genericByteCode = JSON.parse(genericContract.byte_code);

        let depContract = new web3.eth.Contract(
          genericAbi,
          collections.contract_address
        );

        depContract.methods
          .ownerOf(token)
          .call()
          .then((_nftOwnerAddress: string) => {
            setNftOwner(_nftOwnerAddress.toLowerCase());
          });
      } catch (error) {
        console.log("error", error);
      }
    }
  }, [token, genericContract?.id, collections?.id]);

  useEffect(() => {
    fetchCardDetails();
  }, [address, token, chainId]);

  useEffect(() => {
    if (chainId) {
      getSaleContractHandler(chainId);
      getGenericContractHandler();
    }
  }, [chainId]);

  const getSaleContractHandler = async (chainId: number) => {
    let params: FetchHiddednContract = {
      onlyHidden: true,
      singleData: true,
      chainId,
      source: "sale-contract",
    };
    const getDetails = await fetchContracts(params);
    if (getDetails && getDetails.success) {
      setSellContract(getDetails);
    }
  };
  const getGenericContractHandler = async () => {
    let params: FetchHiddednContract = {
      onlyHidden: true,
      singleData: true,

      source: "generic-contract",
    };
    const getDetails = await fetchContracts(params);
    if (getDetails && getDetails.success) {
      setGenericContract(getDetails);
    }
  };

  const updateTheOwnerAddressHandler = (params: UpdateOwnerAddress) => {
    axios
      .post(API_URLS.NFT_OWNER_ADDRESS_UPDATE, { ...params })
      .then(function (res) {
        console.log("onwer address updated");
        fetchCardDetails();
      })
      .catch((error) => {});
  };

  const fetchContracts = (seatchParams: FetchHiddednContract) => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(API_URLS.GET_CONTRACTS, { params: { ...seatchParams } })
        .then(function (res) {
          resolve({
            success: res?.data?.data?.id ? true : false,
            ...res.data.data,
          });
        })
        .catch(function (error) {
          resolve({
            success: false,
          });
        });
    });
  };

  const fetchCardDetails = () => {
    let params: any = {
      // owner_address: account,
      token_id: token,
      contract_address: address,
    };
    axios
      .get(API_URLS.CRYPTOVERSE, { params })
      .then((res: any) => {
        console.log("res", res.data.data);
        setCollections(res.data.data);
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  const goTodetailsPageHandler = (contract_address: any, tokenId: any) => {
    Navigate(`/listitem/${contract_address}/${tokenId}`);
  };

  const fetchListingDetails = (seatchParams: any) => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(API_URLS.GET_SELL_LISTING, { params: { ...seatchParams } })
        .then(function (res) {
          resolve({
            success: res?.data?.data?.id ? true : false,
            ...res.data.data,
          });
        })
        .catch(function (error) {
          resolve({
            success: false,
          });
        });
    });
  };

  const buyHandler = async () => {
    try {
      if (!collections?.id) {
        return;
      }
      const _fetchListingDetails = await fetchListingDetails({
        singleData: true,
        contractAddress: collections.contract_address,
        tokenId: collections.token_id,
      });

      if (!_fetchListingDetails.success || !_fetchListingDetails?.id) {
        toast.error("Unable to fetch listing details.");
        return;
      }

      if (isNaN(parseInt(_fetchListingDetails.item_id))) {
        toast.error("Item id is not set yet.");
        return;
      }
      const saleServiceFee = findValue("sale_service_fee").value
        ? findValue("sale_service_fee").value
        : 0;

      const buyAmount = getPercentageAmount(
        _fetchListingDetails.start_price,
        saleServiceFee,
        true
      );
      let sellAbi = JSON.parse(sellContract.abi);

      const web3 = new Web3(library.provider);
      let currentChainId = await web3.eth.getChainId();
      let reqdChain = sellContract.chain_id;
      if (currentChainId !== reqdChain) {
        switchNetwork(String(reqdChain), library).then(() => buyHandler());
        return;
      }
      let depContract = new web3.eth.Contract(sellAbi, sellContract.address);

      let contractParams = {
        from: String(account),
        to: sellContract.address,
        value: Web3.utils.toWei(String(buyAmount.toFixed(6)), "ether"),

        data: depContract.methods
          .purchaseItem(_fetchListingDetails.item_id)
          .encodeABI(),
      };

      web3.eth
        .estimateGas(contractParams)
        .then((gasEstimate) => {
          web3.eth
            .sendTransaction({ ...contractParams, gas: gasEstimate })
            .on("error", function (err: any) {
              toast.error("An unexpected error has occurred, please try again");
            })
            .on("transactionHash", function (txn: string) {
              let paramsToSave = {
                txn_hash: txn,
                contract_address: sellContract.address,
                chain_id: chainId,
                type: "buy",
                listing_id: _fetchListingDetails.id,
                account,
                token_id: _fetchListingDetails.token_id,
              };
              axios
                .post(API_URLS.SAVE_BUY_LISTING, paramsToSave)
                .then(function (res) {
                  toast.success("Transaction hash saved successfully");
                })
                .catch(function (error) {
                  toast.error(
                    error?.response?.data?.message ||
                      "Unable to save transaction hash"
                  );
                });
            });
        })
        .catch(() => {
          toast.error("Could not estimate gas, please check your balance");
        });
    } catch (error: any) {}
  };

  const DefaultImageShow = (): JSX.Element => {
    return (
      <img
        src={collections?.metadata?.image || "/no-image.jpeg"}
        className="avatar"
        alt="Avatar"
        style={{ width: "100%" }}
      />
    );
  };

  const displayFileHandler = (
    metaData: any,
    mimeType: string | null = null
  ) => {
    try {
      let metaDataJson = null;
      if (typeof metaData === "string") {
        metaDataJson = JSON.parse(metaData);
      } else {
        metaDataJson = metaData;
      }

      if (!metaDataJson) {
        return <DefaultImageShow />;
      }
      const getMetaImage = getFileUrlFromMetaData(metaDataJson, mimeType);
      return (
        <>
          <DisplayFile
            placeholder={getMetaImage.placeholder.path}
            link={getMetaImage.mainFile.path}
            mimeType={getMetaImage.mainFile.mimeType}
          />
        </>
      );
    } catch (error) {
      return <DefaultImageShow />;
    }
  };

  if (!collections || !collections.id) {
    return <h1>No data found</h1>;
  }

  return (
    <div className="crypto">
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="sale">
                <h3>
                  Preview{" "}
                  {collections?.id ? (
                    <UpdateMetaDataBtn nftId={Number(collections.id)}  successHandler={fetchCardDetails}/>
                  ) : null}{" "}
                </h3>
              </div>

              <div className="profile">
                <div className="card-profile">
                  {/* <img
                    src={collections?.metadata?.image || "/no-image.jpeg"}
                    className="avatar"
                    alt="Avatar"
                    style={{ width: "100%" }}
                  /> */}
                  {displayFileHandler(
                    collections.metadata,
                    collections.mimetype ? collections.mimetype : null
                  )}
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="details">
                <div className="sell-block">
                  <span className="product-brand">
                    {collections?.contract_name}
                  </span>
                  {account?.toLowerCase() ===
                  collections?.owner_address.toLowerCase() ? (
                    <button
                      onClick={() => {
                        goTodetailsPageHandler(
                          collections?.contract_address,
                          collections?.token_id
                        );
                      }}
                      className="buttonVerse button2"
                    >
                      <Link to="/listitem">Sell</Link>
                    </button>
                  ) : null}
                </div>

                <p className="product-short-des">
                  {collections?.metadata?.name}
                </p>

                <span
                  className="product-price"
                  title={collections?.owner_address}
                >
                  Owned by :
                  {collections.owner_address.slice(0, 4) +
                    ".." +
                    collections.owner_address.slice(-5)}
                </span>

                <p className="product-sub-heading">Current Price</p>
                {account?.toLowerCase() !==
                collections?.owner_address.toLowerCase() ? (
                  <button
                    className="btnVerse cart-btn"
                    onClick={() => buyHandler()}
                  >
                    Buy Now
                  </button>
                ) : null}
                {/* <button className="btnVerse cart-btn">Sell Now</button> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="detail-des">
        <h2 className="heading">Description</h2>
        <p className="des">{collections?.metadata?.description}</p>
      </section>

      <section className="detail-des">
        <h2 className="heading">Properties</h2>

        <div className="prop-cards">
          {collections.metadata &&
            collections.metadata.attributes &&
            collections.metadata.attributes.map((attr: any) => {
              return (
                <div className="prop">
                  <h5>Trait Type : {attr.trait_type}</h5>
                  <p>Value: {attr.value}</p>
                </div>
              );
            })}
        </div>
      </section>

      <section className="detail-des">
        <h2 className="heading">Details</h2>
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <p className="details-sec">
                Contract Address : {collections.owner_address}
              </p>
              <p className="details-sec">Token ID : {collections?.token_id}</p>
              <p className="details-sec">
                Chain Name :{" "}
                {
                  DEPLOY_CHAIN_OPTS.find(
                    (opt: any) => opt.value === collections?.chain_id
                  )?.label
                }
              </p>
            </div>
          </div>
        </div>
      </section>

      <div className="container">
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Item Activity</Accordion.Header>
            <Accordion.Body>
              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  className="dropdown w-100"
                >
                  Filter
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-1">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="defaultInline1"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="defaultInline1"
                      >
                        Sale
                      </label>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item href="#/action-2">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="defaultInline1"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="defaultInline1"
                      >
                        Listing
                      </label>
                    </div>
                  </Dropdown.Item>

                  <Dropdown.Item href="#/action-2">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="defaultInline1"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="defaultInline1"
                      >
                        Offers
                      </label>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item href="#/action-2">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="defaultInline1"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="defaultInline1"
                      >
                        Collection Offers
                      </label>
                    </div>
                  </Dropdown.Item>

                  <Dropdown.Item href="#/action-2">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="defaultInline1"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="defaultInline1"
                      >
                        Transfers
                      </label>
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Event</th>
                    <th>Price</th>
                    <th>From</th>
                    <th>To</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>List</td>
                    <td>0.11</td>
                    <td>T-KES</td>
                    <td></td>
                    <td>2 days ago</td>
                  </tr>
                  <tr>
                    <td>deppijo</td>
                    <td>0.11</td>
                    <td>deppijo</td>
                    <td></td>
                    <td>2 days ago</td>
                  </tr>
                  <tr></tr>
                </tbody>
              </Table>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
};

export default Cryptoverse;
