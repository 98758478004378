import axios from "axios";
import React, { useEffect, useState } from "react";
import { API_URLS } from "../config";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Web3 from "web3";
import { useWeb3React } from "@web3-react/core";
import { useUserSelector } from "../hooks/selectors";
import { useDispatch } from "react-redux";
import { setUsers } from "../redux/reducers/common.reducer";

const SellFiatPayment = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    trigger,
  } = useForm();
  const { account } = useWeb3React();
  const [paymentid, setPaymentId] = useState<boolean>(false);
  const [userdetails, setUserdetails] = useState<any>([]);

  const userData = useUserSelector();

  const dispatch = useDispatch();

  console.log("userData", userData);

  useEffect(() => {
    if (account) {
      fetchPaymentMethod(account);
    }
  }, [account]);
  console.log("userdetails", userdetails.id);

  const [image, setImage] = useState<any>([]);
  const imageRecieved = (event: FileList | null) => {
    if (event !== null) {
      console.log("event", event[0]);

      setImage(event[0]);
    }
  };
  const fetchPaymentMethod = (walletAddress: any) => {
    let params = {
      walletAddress,
    };

    axios
      .get(API_URLS.MTOKEN, { params })
      .then((res) => {
        if (res.data.data.id) {
          setPaymentId(true);
        } else {
          setPaymentId(false);
        }
        setUserdetails(res.data.data);
      })
      .catch((error) => {});
  };
  const imageUpload = (paymentId: any) => {
    const data = new FormData();
    data.append("document", image);
    data.append("paymentMethodId", paymentId);

    axios
      .post(API_URLS.PAYMENT_IMAGE, data)
      .then(function (res) {
        console.log("res", res.data.data.id);
        fetchPaymentMethod(account);

        toast.success(res?.data?.message || "Payment done");
      })
      .catch(function (error) {
        toast.error(
          error?.response?.data?.message?.message || "Please try again later"
        );
        console.log("error", error);
      });
  };
  const onSubmit = (data: any) => {
    let params: any = {};

    params = {
      fields: {
        firstName: data.firstName,
        lastName: data.lastName,
        legalName: data.legalName,
        cellphoneNumber: data.cellphoneNumber,
        email: data.email,
        ssn: data.ssn,
        dateOfBirth: data.dateOfBirth,
        residenceAddress: {
          street1: data.street1,
          city: data.city,
          state: data.state,
          postalCode: data.postalCode,
          country: data.country,
        },
      },
      blockchains: ["ALL"],
      immediate: true,
      walletAddress: account,
    };

    console.log("params", params);

    axios
      .post(API_URLS.WYRE_CREATE_USER, params)
      .then(function (res) {
        console.log("resnew", res.data.data.onboardingUrl);

        let onBoardingUrl = res.data.data.onboardingUrl;

        toast.success(res?.data?.message || "Payment done");
        window.open(`${onBoardingUrl}`, "_blank");
        userDetailsHandler();
        addPaymentMethodHandler();
      })
      .catch(function (error) {
        toast.error(error?.response?.data?.message || "Please try again later");
        console.log("error", error);
      });
    reset();
  };

  const userDetailsHandler = () => {
    if (account) {
      axios
        .get(API_URLS.GET_USER_DETAILS, { params: { walletAddress: account } })
        .then((res) => {
          dispatch(setUsers(res.data.data));
        })
        .catch(() => {});
    }
  };

  const addPaymentMethodHandler = () => {
    if (account) {
      axios
        .post(API_URLS.CREATE_PAYMENT_METHOD_USING_PLIAD, {
          walletAddress: account,
        })
        .then((res) => {
          fetchPaymentMethod(account);
          toast.success(res?.data?.message || "Payment method added");
        })
        .catch((error) => {
          toast.error(
            error?.response?.data?.message || "Please try again later"
          );
        });
    } else {
      toast.error("Wallet aadress not found");
    }
  };

  const verifyPaymentMethodLink = () => {
    if (account) {
      axios
        .post(API_URLS.GET_WYRE_USER_ONBOARDING_URL, {
          walletAddress: account,
        })
        .then((res) => {
          let onboardingUrl = res?.data?.data?.onboardingUrl;
          if (onboardingUrl) {
            window.open(`${onboardingUrl}`, "_blank");
            toast.success(res?.data?.message || "Onboarding url generated");
          } else {
            toast.error("Please try again later");
          }
        })
        .catch((error) => {
          toast.error(
            error?.response?.data?.message || "Please try again later"
          );
        });
    } else {
      toast.error("Wallet aadress not found");
    }
  };

  return (
    <div className="container pt-5">
      {!paymentid && userData && userData.wyre_user_id ? (
        <>
          <button
            type="button"
            className="btn btn-primary float-right btn-spacing"
            onClick={() => addPaymentMethodHandler()}
          >
            Add/Link Payment Method
          </button>
        </>
      ) : null}

      {userdetails && userdetails.status && userdetails.status != "ACTIVE" ? (
        <button
          type="button"
          className="btn btn-primary float-right btn-spacing"
          onClick={() => verifyPaymentMethodLink()}
        >
          Verify the Payment method
        </button>
      ) : null}

      {userData && userData.id ? (
        <div className="userDetails" style={{ paddingTop: "15px" }}>
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">
                Wyre User Id :{" "}
                {userData.wyre_user_id ? userData.wyre_user_id : "N/A"}
              </h5>
            </div>
          </div>
        </div>
      ) : null}

      {paymentid ? (
        <div className="userDetails">
          <h1>Details of the Bank</h1>

          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Name : {userdetails.name}</h5>

              <h5 className="card-title">
                Name of the user : {userdetails.name_on_method}
              </h5>
              <h5 className="card-title">
                Last 4 Digits : {userdetails.last4_digits}
              </h5>

              <h5 className="card-title">
                Link Type : {userdetails.link_type}
              </h5>
              <h5 className="card-title">
                Finger Print : {userdetails.fingerprint}
              </h5>
              <h5 className="card-title">
                Beneficiary : {userdetails.beneficiary_type}
              </h5>
              <h5 className="card-title">Status : {userdetails.status}</h5>
            </div>
          </div>
        </div>
      ) : null}

      {!userData || (userData && !userData.wyre_user_id) ? (
        <div className="row justify-content-sm-center pt-5">
          <div className="col-sm-6 shadow round pb-3">
            <h1 className="text-center pt-3 text-secondary">Add Wyre User</h1>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group">
                <label className="col-form-label">First Name</label>
                <input
                  type="text"
                  className={`form-control`}
                  {...register("firstName", {
                    required: "First Name is Required",
                    minLength: {
                      value: 3,
                      message: "Minimum Required length is 3",
                    },
                  })}
                />

                {errors.firstName && (
                  <small className="text-danger">
                    {errors.firstName.message}
                  </small>
                )}
              </div>

              <div className="form-group">
                <label className="col-form-label">Last Name</label>
                <input
                  type="text"
                  className={`form-control`}
                  {...register("lastName", {
                    required: "Last Name is Required",
                    minLength: {
                      value: 3,
                      message: "Minimum Required length is 3",
                    },
                  })}
                />

                {errors.lastName && (
                  <small className="text-danger">
                    {errors.lastName.message}
                  </small>
                )}
              </div>

              <div className="form-group">
                <label className="col-form-label">Legal Name</label>
                <input
                  type="text"
                  className={`form-control`}
                  {...register("legalName", {
                    required: "Legal Name is Required",
                    minLength: {
                      value: 3,
                      message: "Minimum Required length is 3",
                    },
                  })}
                />

                {errors.legalName && (
                  <small className="text-danger">
                    {errors.legalName.message}
                  </small>
                )}
              </div>

              <div className="form-group">
                <label className="col-form-label">Phone:</label>
                <input
                  type="text"
                  className={`form-control ${
                    errors.cellphoneNumber && "invalid"
                  }`}
                  {...register("cellphoneNumber", {
                    required: "Phone is Required",
                    pattern: {
                      value:
                        /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
                      message: "Invalid phone no",
                    },
                  })}
                  onKeyUp={() => {
                    trigger("phone");
                  }}
                />
                {errors.phone && (
                  <small className="text-danger">
                    {errors.cellphoneNumber.message}
                  </small>
                )}
              </div>

              <div className="form-group">
                <label className="col-form-label">Email:</label>
                <input
                  type="text"
                  className={`form-control ${errors.email && "invalid"}`}
                  {...register("email", {
                    required: "Email is Required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address",
                    },
                  })}
                />
                {errors.email && (
                  <small className="text-danger">{errors.email.message}</small>
                )}
              </div>

              <div className="form-group">
                <label className="col-form-label">SSN:</label>
                <input
                  type="text"
                  className={`form-control ${errors.ssn && "invalid"}`}
                  {...register("ssn", {
                    required: "SSN is Required",
                    minLength: {
                      value: 9,
                      message: "Minimum Required length is 9",
                    },
                    maxLength: {
                      value: 9,
                      message: "Maximum allowed length is 9 ",
                    },
                  })}
                  onKeyUp={() => {
                    trigger("ssn");
                  }}
                />
                {errors.ssn && (
                  <small className="text-danger">{errors.ssn.message}</small>
                )}
              </div>

              <div className="form-group">
                <label className="col-form-label">Street:</label>
                <input
                  type="text"
                  className={`form-control`}
                  {...register("street1", { required: "Street is Required" })}
                />

                {errors.street1 && (
                  <small className="text-danger">
                    {errors.street1.message}
                  </small>
                )}
              </div>

              <div className="form-group">
                <label className="col-form-label">City:</label>
                <input
                  type="text"
                  className={`form-control`}
                  {...register("city", { required: "City is Required" })}
                />

                {errors.city && (
                  <small className="text-danger">{errors.city.message}</small>
                )}
              </div>

              <div className="form-group">
                <label className="col-form-label">State:</label>
                <input
                  type="text"
                  className={`form-control`}
                  {...register("state", { required: "State is Required" })}
                />

                {errors.state && (
                  <small className="text-danger">{errors.state.message}</small>
                )}
              </div>

              <div className="form-group">
                <label className="col-form-label">Postal Code:</label>
                <input
                  type="text"
                  className={`form-control`}
                  {...register("postalCode", {
                    required: "Postal Code is Required",
                  })}
                />

                {errors.postalCode && (
                  <small className="text-danger">
                    {errors.postalCode.message}
                  </small>
                )}
              </div>

              <div className="form-group">
                <label className="col-form-label">Country:</label>
                <input
                  type="text"
                  className={`form-control`}
                  {...register("country", { required: "Country is Required" })}
                />

                {errors.city && (
                  <small className="text-danger">
                    {errors.country.message}
                  </small>
                )}
              </div>

              <div className="form-group">
                <label className="col-form-label">Date Of Birth:</label>
                <input
                  type="date"
                  className={`form-control`}
                  {...register("dateOfBirth", {
                    required: "Country is Required",
                  })}
                />

                {errors.dateOfBirth && (
                  <small className="text-danger">
                    {errors.dateOfBirth.message}
                  </small>
                )}
              </div>

              {/* <div className="form-group">
                <label className="col-form-label">Account Number</label>
                <input
                  type="text"
                  className={`form-control`}
                  {...register("accountNumber", {
                    required: "Account Number is Required",
                    minLength: {
                      value: 3,
                      message: "Minimum Required length is 3",
                    },
                  })}
                />

                {errors.accountNumber && (
                  <small className="text-danger">
                    {errors.accountNumber.message}
                  </small>
                )}
              </div> */}

              {/* <div className="form-group">
                <label className="col-form-label">Routing Number</label>
                <input
                  type="text"
                  className={`form-control`}
                  {...register("routingNumber", {
                    required: "Routing Number is Required",
                    minLength: {
                      value: 3,
                      message: "Minimum Required length is 3",
                    },
                  })}
                />

                {errors.routingNumber && (
                  <small className="text-danger">
                    {errors.routingNumber.message}
                  </small>
                )}
              </div> */}

              {/* <div className="form-group">
                <label className="">Account Type</label>

                <select
                  className="select-tag-block"
                  {...register("accountType")}
                >
                  <option value="CHECKING">CHECKING</option>
                  <option value="SAVING">SAVING</option>
                </select>

                {errors.accountType && (
                  <small className="text-danger">
                    {errors.accountType.message}
                  </small>
                )}
              </div> */}

              {/* <div className="form-group">
                <input
                  type="file"
                  accept=".jpg,.png,.pdf"
                  name="picture"
                  onChange={(e) => imageRecieved(e.target.files)}
                />
              </div> */}

              <input
                type="submit"
                className="btn btn-primary my-3"
                value="Add Payment Method"
              />
            </form>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SellFiatPayment;
