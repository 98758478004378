import { createSlice } from "@reduxjs/toolkit";

export const commonSlice = createSlice({
  name: "common",
  initialState: {
    rates: {} as any,
    settings: [] as any,
    users: {} as any,
  },
  reducers: {
    setRates: (state, action) => {
      state.rates = action.payload;
    },
    /* setMToken: (state, action) => {
			state.mTokens = action.payload;
		}, */
    setSettings: (state, action) => {
      state.settings = action.payload;
    },
    setUsers: (state, action) => {
      state.users = action.payload;
    },
  },
});

export const { setRates, setSettings, setUsers } = commonSlice.actions;

export default commonSlice.reducer;
