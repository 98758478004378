import React, { useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import ContractList from "../components/contract/contractList";

const MyContractPage = () => {
  const { account } = useWeb3React();
  const ethereum = (window as any).ethereum;
  const { chainId, library } = useWeb3React();

  return (
    <div className="container">
      <div className=" mb-4 mt-5">
        <h5>Contracts</h5>
        {account && chainId ? (
          <ContractList chainId={chainId} ownerAddress={String(account)} />
        ) : null}
      </div>
    </div>
  );
};

export default MyContractPage;
