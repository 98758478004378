import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import {
  copyText,
  sliceString,
  capitalizeFirstLetter,
  copyContractAddress,
} from "../../common/functions";
import { API_URLS } from "../../config";
import Web3 from "web3";
import { useWeb3React } from "@web3-react/core";
import { switchNetwork } from "../../web3/functions";
import { Modal } from "react-bootstrap";
import { DEPLOY_CHAIN_OPTS } from "../../web3/connectors";
import TxnTableModal from "./txnTableModal";

function MintTable() {
  const { account } = useWeb3React();
  const { chainId, library } = useWeb3React();
  const [tableData, setData] = useState<any>({});
  const [itemsPerPage, setItemsPerPage] = useState<any>(10);
  const [activePage, setActivePage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(0);
  const [sendToAddres, setSendToAddres] = useState<string>("");
  const [showModal, setShowModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<any>(null);
  const [showTxnHistoryModal, setShowTxnHistoryModal] =
    useState<boolean>(false);
  const [selectedTxnRow, setSelectedTxnRow] = useState<any>(null);

  useEffect(() => {
    if (account) {
      loadInitialData(1);
    }
  }, [account]);

  const loadInitialData = (page: number) => {
    let params: any = {
      page,
      perPage: itemsPerPage,
      withAbi: true,
      owner_address: account,
    };
    axios
      .get(API_URLS.MINT_NFT, { params })
      .then((res) => {
        setPageCount(res.data.data.totalPage);
        setData(res.data.data);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  const handlePageClick = async (data: any) => {
    let currentPage = data.selected + 1;
    setActivePage(currentPage);
    loadInitialData(currentPage);
  };

  const transferHandler = async () => {
    try {
      let row =
        tableData && tableData.data && tableData.data.length
          ? { ...tableData.data[selectedIndex] }
          : {};
      if (!row.id) {
        toast.error("Invalid data");
        return;
      }
      if (!Web3.utils.isAddress(sendToAddres)) {
        toast.error("Invalid wallet address given");
        return;
      }

      let abi = row.abi;
      abi = abi.replace("'", "");
      abi = abi.slice(0, -1);
      row.abi = abi;

      const web3 = new Web3(library.provider);

      let currentChainId = await web3.eth.getChainId();

      let selectedChain = row.chain_id;
      let tokenId = row.token_id;

      if (currentChainId !== selectedChain) {
        switchNetwork(String(selectedChain), library).then(() =>
          transferHandler()
        );
      }

      let depContract = new web3.eth.Contract(
        JSON.parse(row.abi),
        row.contract_address
      );

      let fromAddress = String(account);
      let toAddress = row.contract_address;

      let params = {
        from: fromAddress,
        to: toAddress,
        data: depContract.methods
          .safeTransferFrom(fromAddress, sendToAddres, tokenId)
          .encodeABI(),
      };

      depContract.methods
        .ownerOf(tokenId)
        .call()
        .then((address: string) => {
          if (fromAddress.toLowerCase() === address.toLowerCase()) {
            console.log("owner verified");

            web3.eth
              .estimateGas(params)
              .then((gasEstimate) => {
                web3.eth
                  .sendTransaction({ ...params, gas: gasEstimate })
                  .on("error", function (err: any) {
                    toast.error(
                      "An unexpected error has occurred, please try again"
                    );
                  })
                  .on("transactionHash", function (txn: string) {
                    let paramsToSave = {
                      txn_hash: txn,
                      contract_id: row.contract_id,
                      chain_id: selectedChain,
                      from_address: fromAddress,
                      type: "transfer",
                    };

                    axios
                      .post(API_URLS.TRANSACTION_SAVE, paramsToSave)
                      .then(function (res) {
                        toast.success("Transaction  saved successfully");
                      })
                      .catch(function (error) {
                        toast.error(
                          error?.response?.data?.message ||
                            "Unable to save transaction hash"
                        );
                      });

                    console.log("transactionHash", txn);
                    onCloseModalHandler();
                  });
              })
              .catch(() => {
                toast.error(
                  "Could not estimate gas, please check your balance"
                );
              });
          } else {
            toast.error("You are not the owner!");
          }
        })
        .catch(() => {
          toast.error("Could not verify the token, please try again");
        });
    } catch (err:any) {
      console.log("error", err.message);
      toast.error("Something went wrong , please try again later");
    }
  };

  const onCloseModalHandler = () => {
    setShowModal(false);
    setSelectedIndex(null);
    setSendToAddres("");
  };
  const showModalHandler = (index: number) => {
    setShowModal(true);
    setSelectedIndex(index);
  };

  return (
    <div className="container mt-4">
      <table className="table w-100">
        <thead className="thead-dark">
          <tr>
            <th>Contract Address</th>
            <th>Owner Address</th>
            <th>Token No.</th>
            <th>Chain Name</th>
            <th>Price</th>
            <th>Created At</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {tableData?.data?.length ? (
            tableData.data.map((row: any, index: number) => (
              <tr key={row.id}>
                <td
                  title={row.contract_address}
                  onClick={() =>
                    copyContractAddress(row.contract_address, row.chain_id)
                  }
                >
                  {sliceString(row.contract_address)}
                </td>
                <td
                  title={row.owner_address}
                  onClick={() => copyText(row.owner_address)}
                >
                  {sliceString(row.owner_address)}
                </td>
                <td> {row.token_id}</td>
                <td>
                  {
                    DEPLOY_CHAIN_OPTS.find(
                      (opt: any) => opt.value === row.chain_id
                    )?.label
                  }
                </td>
                <td>{(!isNaN(row.price)) ? row.price.toFixed(6)  : 'N/A'}</td>
                <td>{String(new Date(row.created_at))}</td>
                <td>
                  <div className="btn-group">
                    <button
                      className=" btn-spacing btn btn-dark"
                      onClick={() => showModalHandler(index)}
                    >
                      Transfer
                    </button>
                    <button
                      className=" btn btn-dark"
                      title="Transaction History"
                      onClick={() => {
                        setShowTxnHistoryModal(true);
                        setSelectedTxnRow(row);
                      }}
                    >
                      <i className="fa fa-history" aria-hidden="true" />
                    </button>
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td>No transaction found</td>
            </tr>
          )}
        </tbody>
      </table>
      <ReactPaginate
        previousLabel={"previous"}
        nextLabel={"next"}
        breakLabel={"..."}
        pageCount={pageCount}
        // marginPagesDisplayed={2}
        pageRangeDisplayed={itemsPerPage}
        onPageChange={handlePageClick}
        containerClassName={"pagination justify-content-center"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        breakClassName={"page-item"}
        breakLinkClassName={"page-link"}
        activeClassName={"active"}
      />
      <div className="transfer-modal">
        <Modal
          className=" modal"
          show={showModal}
          onHide={() => onCloseModalHandler()}
          centered={true}
        >
          <Modal.Header closeButton>
            <h5 className="modal-title">Transfer</h5>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label htmlFor="sendToWalletAddress">Wallet Address</label>
              <input
                type="text"
                className="form-control"
                id="sendToWalletAddress"
                name="sendToWalletAddress"
                placeholder="Enter waller address"
                value={sendToAddres}
                onChange={(e) => setSendToAddres(e.target.value)}
              />
            </div>
            <button
              type="button"
              onClick={transferHandler}
              className="btn btn-primary"
            >
              Transfer
            </button>
          </Modal.Body>
        </Modal>
        {selectedTxnRow && selectedTxnRow.id ? (
          <TxnTableModal
            isOpen={showTxnHistoryModal}
            onClose={() => setShowTxnHistoryModal(false)}
            tokenId={selectedTxnRow.token_id}
            contractId={selectedTxnRow.contract_id}
          />
        ) : null}
      </div>
    </div>
  );
}

export default MintTable;
