import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import { getPercentageAmount } from "../../common/functions";
import { API_URLS } from "../../config";
import Web3 from "web3";
import { useWeb3React } from "@web3-react/core";
import { switchNetwork } from "../../web3/functions";
import { FetchHiddednContract } from "../../containers/listitem";
import { useSettingsSelector } from "../../hooks/selectors";
import { useNavigate } from "react-router-dom";

interface SaleListProps {
  ownerAddress?: string;
  chainId?: number;
}

const SaleList = ({ ownerAddress }: SaleListProps) => {
  const [tableData, setTableData] = useState<any>([]);
  const [itemsPerPage, setItemsPerPage] = useState<any>(8);
  const [activePage, setActivePage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(0);
  const [selectedIndex, setSelectedIndex] = useState<any>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [sellContract, setSellContract] = useState<any>(null);
  const { chainId, library } = useWeb3React();
  const { account } = useWeb3React();
  const findValue = useSettingsSelector();
  const Navigate = useNavigate();

  useEffect(() => {
    let initialPage = 1;
    setActivePage(initialPage);
    loadInitialData(initialPage);
  }, [ownerAddress, chainId]);

  useEffect(() => {
    if (chainId) {
      getSaleContractHandler(chainId);
    }
  }, [chainId]);
  const getSaleContractHandler = async (chainId: number) => {
    let params: FetchHiddednContract = {
      onlyHidden: true,
      singleData: true,
      chainId,
      source: "sale-contract",
    };
    const getDetails = await fetchContracts(params);
    if (getDetails && getDetails.success) {
      setSellContract(getDetails);
    }
  };
  const fetchContracts = (seatchParams: FetchHiddednContract) => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(API_URLS.GET_CONTRACTS, { params: { ...seatchParams } })
        .then(function (res) {
          resolve({
            success: res?.data?.data?.id ? true : false,
            ...res.data.data,
          });
        })
        .catch(function (error) {
          resolve({
            success: false,
          });
        });
    });
  };
  const goTodetailsPageHandler = (contractAddress: any, tokenId: any) => {
    Navigate(`/mynft-details/${contractAddress}/${tokenId}`);
  };
  const loadInitialData = (page: number) => {
    let params: any = {
      page,
      perPage: itemsPerPage,
    };

    if (ownerAddress) {
      params["ownerAddress"] = ownerAddress;
      params["excludeOwner"] = true;
    }
    if (chainId) {
      params["chainId"] = chainId;
    }

    axios
      .get(API_URLS.GET_SELL_LISTING, { params })
      .then((res) => {
        setPageCount(res.data.data.totalPage);
        console.log("table",res.data.data.data)
        setTableData(res.data.data.data);

      })
      .catch((error) => {});
  };

  const handlePageClick = async (data: any) => {
    let currentPage = data.selected + 1;
    setActivePage(currentPage);
    loadInitialData(currentPage);
  };

  const successHandler = (): void => {
    loadInitialData(activePage);
    setShowModal(false);
  };

  const buyHandler = async (sellItemData: any) => {
    try {
      if (!sellContract?.abi || !sellContract?.byte_code) {
        toast.error("Abi or bytecode is missing");
        return;
      }

      if (isNaN(parseInt(sellItemData.item_id))) {
        toast.error("Item id is not set yet.");
        return;
      }
      const saleServiceFee = findValue("sale_service_fee").value
        ? findValue("sale_service_fee").value
        : 0;

      const buyAmount = getPercentageAmount(
        sellItemData.start_price,
        saleServiceFee,
        true
      );
      let sellAbi = JSON.parse(sellContract.abi);
      let sellByteCode = JSON.parse(sellContract.byte_code);

      const web3 = new Web3(library.provider);
      let currentChainId = await web3.eth.getChainId();
      let reqdChain = sellContract.chain_id;
      if (currentChainId !== reqdChain) {
        switchNetwork(String(reqdChain), library).then(() =>
          buyHandler(sellItemData)
        );
        return;
      }
      let depContract = new web3.eth.Contract(sellAbi, sellContract.address);

      let contractParams = {
        from: String(account),
        to: sellContract.address,
        value: Web3.utils.toWei(String(buyAmount.toFixed(6)), "ether"),

        data: depContract.methods
          .purchaseItem(sellItemData.item_id)
          .encodeABI(),
      };

      web3.eth
        .estimateGas(contractParams)
        .then((gasEstimate) => {
          web3.eth
            .sendTransaction({ ...contractParams, gas: gasEstimate })
            .on("error", function (err: any) {
              toast.error("An unexpected error has occurred, please try again");
            })
            .on("transactionHash", function (txn: string) {
              let paramsToSave = {
                txn_hash: txn,
                contract_address: sellContract.address,
                chain_id: chainId,
                type: "buy",
                listing_id: sellItemData.id,
                account,
                token_id: sellItemData.token_id,
              };
              axios
                .post(API_URLS.SAVE_BUY_LISTING, paramsToSave)
                .then(function (res) {
                  toast.success("Transaction hash saved successfully");
                })
                .catch(function (error) {
                  toast.error(
                    error?.response?.data?.message ||
                      "Unable to save transaction hash"
                  );
                });
            });
        })
        .catch(() => {
          toast.error("Could not estimate gas, please check your balance");
        });
    } catch (error) {
      console.log("err", error);
      toast.error(error?.message || "Please try again later");
    }
  };

  return (
    <div className="sale-list mt-5">
      <div className="container">
        <div className="row">
          {tableData?.length ? (
            tableData.map((elem: any) => {
              return (
                <div key={elem.id} className="col-lg-3">
                  <div className="card">
                    <img src="/no-image.jpeg" alt="prod" onClick={() => {
                    goTodetailsPageHandler(
                      elem.contract_address,
                      elem.token_id
                    );
                  }} />
                    <div className="card-body">
                      <div className="token-id">
                        <h6>Token: {elem.token_id}</h6>
                      </div>
                      <div className="collection-name">
                        <h6>
                          {" "}
                          Price:{" "}
                          {elem.type === "action"
                            ? `${
                                elem.start_price
                                  ? elem.start_price.toFixed(4)
                                  : 0
                              } - ${
                                elem.end_price ? elem.end_price.toFixed(4) : 0
                              }`
                            : `${
                                elem.start_price
                                  ? elem.start_price.toFixed(4)
                                  : 0
                              }`}
                        </h6>
                      </div>
                      <button
                        type="button"
                        onClick={() => buyHandler(elem)}
                        className="btn btn-primary"
                      >
                        Buy
                      </button>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <h2>No Data Found</h2>
          )}
        </div>
      </div>
      {pageCount > 0 ? (
        <ReactPaginate
          previousLabel={"previous"}
          nextLabel={"next"}
          breakLabel={"..."}
          pageCount={pageCount}
          pageRangeDisplayed={itemsPerPage}
          onPageChange={handlePageClick}
          containerClassName={"pagination justify-content-center"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default SaleList;
