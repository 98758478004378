import React from "react";
import {ModelViewer  }  from "./ModelViewer";

const imgTypes = [
  "image/svg+xml",
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/gif",
  "image/webp",
];
const videoTypes = ["video/webm", "video/mp4", "video/ogg", "video/webm", "video/x-m4v", "video/quicktime"];
const audioTypes = ["audio/webm", "audio/mpeg", "audio/wav", "audio/ogg"];
const THREE_D = ["model/gltf-binary", "model/gltf+json"];

interface DisplateFileProps {
  link: string;
  mimeType: string;
  placeholder?: string;
}

const DisplayFile = (props: DisplateFileProps) => {

  console.log('props',props )
  return (
    <>
      {imgTypes.includes(props.mimeType) ? (
        <img src={props.link} />
      ) : videoTypes.includes(props.mimeType) ? (
        <video width={"100%"} height="240" controls>
          <source src={props.link} type={props.mimeType} />
          Your browser does not support the video tag.
        </video>
      ) : audioTypes.includes(props.mimeType) ? (
        <audio controls>
          <source src={props.link} type={props.mimeType} />
          Your browser does not support the audio element.
        </audio>
      ) : THREE_D.includes(props.mimeType) && typeof window !== "undefined" ? (
       <>
         {/* <ModelViewer link={props.link} poster={props?.placeholder || "/no-image.jpeg"}/> */}
       </>
      ) : null}
    </>
  );
};

export default DisplayFile;
