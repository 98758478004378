import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import {
  SelectedContractInterface,
  ContractUriType,
} from "../../containers/deployPage";
import UpdateContractUriForm from "./updateContractUriForm";

interface UpdateContractUriModalProps {
  selectedContract: SelectedContractInterface;
  isOpen: boolean;
  onClose: () => void;
  successHandler?: () => void;
}
const UpdateContractUriModal = ({
  selectedContract,
  isOpen,
  onClose,
  successHandler,
}: UpdateContractUriModalProps) => {
  const urlInputName: string =
    selectedContract.type === ContractUriType.BASEURI
      ? "base_token_uri"
      : "contract_uri";
  const contractDetails: any = selectedContract.contractDetails;

  const title =
    selectedContract.type === ContractUriType.BASEURI
      ? "Token URI"
      : "Contract URI";

  return (
    <>
      <Modal size={"lg"} centered onHide={onClose} show={isOpen}>
        <Modal.Header closeButton> Update {title} </Modal.Header>
        <Modal.Body>
          <div className="container">
            <UpdateContractUriForm
              urlInputName={urlInputName}
              contractDetails={contractDetails}
              type={selectedContract.type}
              onClose={onClose}
              successHandler={successHandler}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default UpdateContractUriModal;
