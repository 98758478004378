import { toast } from "react-toastify";
import moment from "moment";

export const changeModalSize = () => {
  setTimeout(() => {
    let elem: any = document.querySelector(".walletconnect-modal__base");
    if (elem) {
      elem.classList.add("w-25");
    }
  }, 1000);
};

export const sliceString = (
  str: string,
  start: number = 4,
  end: number = -5
) => {
  return str ? str.slice(0, start) + ".." + str.slice(end) : "";
};

export const copyText = (text: string) => {
  window.navigator.clipboard.writeText(text);
  toast.success("Text copied!");
};
export const copyTokenId = (text: String) => {
  let tokenIdLength = text.split(",");

  // console.log(tokenIdLength.length)
  return tokenIdLength.length;
};
export function checkNumber(e: any) {
  let value = e.target.value;

  if (isNaN(value)) {
    e.target.value = "";
    return;
  }
  let min = e.target.getAttribute("min");
  if (min && value < parseFloat(min)) {
    value = parseFloat(min);
  }
  let max = e.target.getAttribute("max");
  if (max && value > parseFloat(max)) {
    value = parseFloat(max);
  }

  e.target.value = value;
}

export const copyTransaction = (text: string, chain_id: number) => {
  let mumbai = `https://mumbai.polygonscan.com/tx/${text}`;
  if (chain_id === 80001) {
    console.log(chain_id);
    // (`https://mumbai.polygonscan.com/${text}`)
    window.navigator.clipboard.writeText(text);
    console.log(`https://mumbai.polygonscan.com/tx/${text}`);
    window.open(`https://mumbai.polygonscan.com/tx/${text}`, "_blank");
  } else if (chain_id === 137) {
    window.navigator.clipboard.writeText(text);

    window.open(`https://polygonscan.com/tx/${text}`, "_blank");
  } else if (chain_id === 43114) {
    window.navigator.clipboard.writeText(text);

    window.open(`https://snowtrace.io/tx/${text}`, "_blank");
  } else if (chain_id === 43113) {
    window.open(`https://testnet.snowtrace.io/tx/${text}`, "_blank");
  } else if (chain_id === 1) {
    window.navigator.clipboard.writeText(text);

    window.open(`https://etherscan.io/tx/${text}`, "_blank");
  } else if (chain_id === 4) {
    window.navigator.clipboard.writeText(text);

    window.open(`https://rinkeby.etherscan.io/tx/${text}`, "_blank");
  }
  // window.navigator.clipboard.writeText(text);
  // toast.success('Text copied!')
};

export const copyContractAddress = (text: string, chain_id: number) => {
  let mumbai = `https://mumbai.polygonscan.com/address/${text}`;
  if (chain_id === 80001) {
    console.log(chain_id);
    // (`https://mumbai.polygonscan.com/${text}`)
    window.navigator.clipboard.writeText(text);
    console.log(`https://mumbai.polygonscan.com/address/${text}`);
    window.open(`https://mumbai.polygonscan.com/address/${text}`, "_blank");
  } else if (chain_id === 137) {
    window.navigator.clipboard.writeText(text);

    window.open(`https://polygonscan.com/address/${text}`, "_blank");
  } else if (chain_id === 43114) {
    window.navigator.clipboard.writeText(text);

    window.open(`https://snowtrace.io/address/${text}`, "_blank");
  } else if (chain_id === 43113) {
    window.open(`https://testnet.snowtrace.io/address/${text}`, "_blank");
  } else if (chain_id === 1) {
    window.navigator.clipboard.writeText(text);

    window.open(`https://etherscan.io/address/${text}`, "_blank");
  } else if (chain_id === 4) {
    window.navigator.clipboard.writeText(text);

    window.open(`https://rinkeby.etherscan.io/address/${text}`, "_blank");
  }
  // window.navigator.clipboard.writeText(text);
  // toast.success('Text copied!')
};

export const copyFromAddress = (text: string, chain_id: number) => {
  let mumbai = `https://mumbai.polygonscan.com/address/${text}`;
  if (chain_id === 80001) {
    console.log(chain_id);
    // (`https://mumbai.polygonscan.com/${text}`)
    window.navigator.clipboard.writeText(text);
    console.log(`https://mumbai.polygonscan.com/address/${text}`);
    window.open(`https://mumbai.polygonscan.com/address/${text}`, "_blank");
  } else if (chain_id === 137) {
    window.navigator.clipboard.writeText(text);

    window.open(`https://polygonscan.com/address/${text}`, "_blank");
  } else if (chain_id === 43114) {
    window.navigator.clipboard.writeText(text);

    window.open(`https://snowtrace.io/address/${text}`, "_blank");
  } else if (chain_id === 43113) {
    window.open(`https://testnet.snowtrace.io/address/${text}`, "_blank");
  } else if (chain_id === 1) {
    window.navigator.clipboard.writeText(text);

    window.open(`https://etherscan.io/address/${text}`, "_blank");
  } else if (chain_id === 4) {
    window.navigator.clipboard.writeText(text);

    window.open(`https://rinkeby.etherscan.io/address/${text}`, "_blank");
  }
};

export function getPercentageAmount(
  amount: number,
  percentage: number,
  addIt: boolean = false
) {
  if (amount == 0) {
    return amount;
  }
  let percentageAmount = (amount * percentage) / 100;
  if (addIt) {
    percentageAmount = percentageAmount + amount;
  }

  return percentageAmount;
}

export const capitalizeFirstLetter = (string: any) => {
  if (string && string.length) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  } else {
    return string;
  }
};
export interface FileUrlConsist {
  path: string;
  mimeType: string;
}
export interface FileUrlMetaData {
  mainFile: FileUrlConsist;
  placeholder: FileUrlConsist;
}

export function getFileUrlFromMetaData(
  metaData: any,
  mimeType:string |null=null
): FileUrlMetaData {
  let fileUrlObj: FileUrlMetaData = {
    mainFile: { path: "", mimeType: "" },
    placeholder: { path: "", mimeType: "" },
  };

  if (metaData.placeholderUrl) {
    fileUrlObj["placeholder"]["path"] = metaData.placeholderUrl
      ? String(metaData.placeholderUrl)
      : "";
    fileUrlObj["placeholder"]["mimeType"] = "image/jpeg";
  }

  if (!mimeType) {
    fileUrlObj["mainFile"]["mimeType"] =  "image/jpeg";
  } else {
    fileUrlObj["mainFile"]["mimeType"] = mimeType;
  }

  if (metaData.file) {
    fileUrlObj["mainFile"]["path"] = metaData.file ? String(metaData.file) : "";
    //fileUrlObj["mainFile"]["mimeType"] = "image/jpeg";
  } else if (metaData.image) {
    fileUrlObj["mainFile"]["path"] = metaData.image
      ? String(metaData.image)
      : "";
    //fileUrlObj["mainFile"]["mimeType"] = "image/jpeg";
  } else if (metaData.video) {
    fileUrlObj["mainFile"]["path"] = metaData.video
      ? String(metaData.video)
      : "";
    fileUrlObj["mainFile"]["mimeType"] = "video/mp4";
  } else if (metaData.audio) {
    fileUrlObj["mainFile"]["path"] = metaData.audio
      ? String(metaData.audio)
      : "";
    //fileUrlObj["mainFile"]["mimeType"] = "audio/wav";
  } else {
    fileUrlObj["mainFile"]["path"] = "/no-image.jpeg";
    //fileUrlObj["mainFile"]["mimeType"] = "image/jpeg";
  }
  fileUrlObj["placeholder"]["path"] = fileUrlObj.placeholder.path.replace(
    "ipfs://",
    "https://ipfs.io/ipfs/"
  );
  fileUrlObj["mainFile"]["path"] = fileUrlObj.mainFile.path.replace(
    "ipfs://",
    "https://ipfs.io/ipfs/"
  );

  return fileUrlObj;
}

export function getNftName(metaData: any) {
  let name = null;

  if (metaData.name) {
    name = metaData.name;
  }
  return name;
}

export function dateFormatHadler(date: string, withTime: boolean = true) {
  let response = "";
  if (withTime) {
    response = moment(date).format("MM/DD/YYYY, h:mm:ss a");
  } else {
    response = moment(date).format("MM/DD/YYYY");
  }
  return response;
}
