import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';

interface FormTextInputProps {
  onChange: (...args: any) => void;
  onBlur: () => void;
  onFocus?: () => void;
  onPaste?: (args: React.ClipboardEvent) => void;
  value: any;
  name: string;
  type: string;
  disabled?: boolean;
  className?: string;
  inputRef?: any;
  placeholder?: string;
  error?: any;
  id?: string;
  pattern?: string;
  maxLength?: number;
  appendText?: string;
  step?: string;
  readOnly?: boolean;
}

function FormTextInput({
  onChange,
  onBlur,
  onPaste,
  value,
  name,
  inputRef,
  type,
  placeholder,
  error,
  id,
  pattern,
  disabled,
  onFocus,
  maxLength,
  className,
  step,
  appendText,
  readOnly,
}: FormTextInputProps) {
  return (
    <>
    <div>
      <Form.Control
        className={className ? className : 'form-control'}
        type={type}
        name={name}
        value={value || ''}
        id={id}
        step={step}
        onFocus={onFocus ? onFocus : () => {}}
        maxLength={maxLength}
        pattern={pattern}
        onWheel={(evt: any) => evt.target.blur()}
        onBlur={onBlur}
        placeholder={placeholder}
        onChange={(evt) => onChange(evt.target.value)}
        ref={inputRef}
        disabled={disabled}
        autoComplete={'off'}
        onPaste={onPaste}
        readOnly={readOnly}
      />
      {error && error.message ? (
        <p className="form-error">
            {error.message}
            </p>
      ) : null}
      </div>
    </>
  );
}

export default FormTextInput;