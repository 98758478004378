import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import debounce from "lodash/debounce";
import { toast } from "react-toastify";
import {
  copyText,
  sliceString,
  capitalizeFirstLetter,
  copyTransaction,
  copyFromAddress,
} from "../../common/functions";
import { API_URLS } from "../../config";
import Web3 from "web3";
import { useWeb3React } from "@web3-react/core";
import { switchNetwork } from "../../web3/functions";
import { Modal } from "react-bootstrap";
import { DEPLOY_CHAIN_OPTS } from "../../web3/connectors";
import ContractRoyaltyFormModal from "./contractRoyaltyFormModal";

interface ContractListProps {
  ownerAddress: string;
  chainId: number;
}

const ContractList = ({ ownerAddress, chainId }: ContractListProps) => {
  const [tableData, setData] = useState<any>([]);
  const [itemsPerPage, setItemsPerPage] = useState<any>(10);
  const [activePage, setActivePage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(0);
  const [selectedIndex, setSelectedIndex] = useState<any>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [searchField, setSearchField] = useState<any>("");

  useEffect(() => {
    let initialPage = 1;
    setActivePage(initialPage);
    loadInitialData(initialPage);
  }, [ownerAddress, chainId]);

  const loadInitialData = (page: number, queryObj = {}) => {
    let params: any = {
      page,
      perPage: itemsPerPage,
      ownerAddress,
      chainId,
      // address:searchField
      ...queryObj,
    };
    axios
      .get(API_URLS.GET_CONTRACTS, { params })
      .then((res) => {
        setPageCount(res.data.data.totalPage);
        setData(res.data.data);
      })
      .catch((error) => {});
  };



  const handlePageClick = async (data: any) => {
    let currentPage = data.selected + 1;
    setActivePage(currentPage);
    loadInitialData(currentPage);
  };

  const successHandler = (): void => {
    loadInitialData(activePage);
    setShowModal(false);
  };
  

  // debouncing code start

  const searchByBreed = debounce((searchText) => {
    let initialPage = 1;
    setActivePage(initialPage);
    let params:any = {};

    if (searchText.length > 3) {
      params['address'] = searchText
    }
    loadInitialData(initialPage,params)
  }, 800);
  // end debouncing
  const handleText = (text: any) => {
    // console.log("text",text)

    const searchFieldString = text.toLowerCase();
    // console.log("searchFieldString", searchFieldString);
    // setSearchField(searchFieldString);
    searchByBreed(searchFieldString)
    // handleDebounce()
  };

  const handleDebounce = debounce(() => {
    {
      console.log("searchField", searchField);
    }
    // loadInitialData(activePage,searchField)
  }, 500);

  // const filteredContracts = tableData?.data?.filter((val: any) => {
  //   return val.address.toLocaleLowerCase().includes(searchField);
  // });

  return (
    <>
      {/* {console.log("tableData",tableData)} */}
      {selectedIndex && selectedIndex.address ? (
        <ContractRoyaltyFormModal
          contractAddress={selectedIndex.address}
          royaltyWallet={
            selectedIndex.royalty_wallet ? selectedIndex.royalty_wallet : null
          }
          royaltyPercent={
            selectedIndex.royalty_percent ? selectedIndex.royalty_percent : 0
          }
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          successHandler={successHandler}
        />
      ) : null}
      {console.log("tableData", tableData.data)}
      {/* {console.log("searchField", searchField)} */}

      {/* <input
        type="search"
        className={`search-box form-control mt-5`}
        placeholder="Search Contract"
        onChange={onChangeHandler}
      /> */}
      <input
        type="search"
        className={`search-box form-control mt-5`}
        placeholder="Search Contract"
        onChange={(e) => handleText(e.target.value)}
      />

      <table className="table w-100 mt-5">
        <thead className="thead-dark">
          <tr>
            <th>Txn_Hash</th>
            <th>Contract Name</th>
            <th>Contract Address</th>
            <th>From Address</th>
            <th>Network</th>
            <th>Chain Name</th>
            <th>Status</th>
            <th>Created At</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {tableData?.data?.length ? (
            tableData?.data?.map((row: any, index: number) => (
              <tr key={row.id}>
                <td>
                  {row.txn_hash === null
                    ? "N/A"
                    : row.txn_hash.slice(0, 4) + ".." + row.txn_hash.slice(-5)}
                </td>
                <td>{row.name}</td>
                {/* <td title={row.address}>{row.address.slice(0,4)+".."+row.address.slice(-5)}</td> */}
                <td title={row.address}>{row.address}</td>

                <td title={row.from_address}>
                  {row.from_address.slice(0, 4) +
                    ".." +
                    row.from_address.slice(-5)}
                </td>
                <td>{row.network}</td>
                <td>
                  {
                    DEPLOY_CHAIN_OPTS.find(
                      (opt: any) => opt.value === row.chain_id
                    )?.label
                  }
                </td>
                <td>{row.status}</td>

                <td>{String(new Date(row.created_at))}</td>

                <td>
                  <div className="btn-group">
                    <button
                      className="btn-spacing btn btn-dark"
                      title="Manage Royalty"
                      onClick={() => {
                        setShowModal(true);
                        setSelectedIndex(row);
                      }}
                    >
                      <i className="fa fa-gear" />
                    </button>
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td>No data found</td>
            </tr>
          )}
        </tbody>
      </table>

      <ReactPaginate
        previousLabel={"previous"}
        nextLabel={"next"}
        breakLabel={"..."}
        pageCount={pageCount}
        pageRangeDisplayed={itemsPerPage}
        onPageChange={handlePageClick}
        containerClassName={"pagination justify-content-center"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        breakClassName={"page-item"}
        breakLinkClassName={"page-link"}
        activeClassName={"active"}
      />
    </>
  );
};

export default ContractList;
