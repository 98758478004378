import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import ManageRoyaltyForm from "./manageRoyaltyForm";

interface ContractRoyaltyFormModalProps {
  contractAddress: string;
  royaltyWallet?: string | null;
  royaltyPercent?: number | null;
  isOpen: boolean;
  onClose: () => void;
  successHandler?: () => void;
}
const ContractRoyaltyFormModal = ({
  contractAddress,
  royaltyWallet,
  royaltyPercent,
  isOpen,
  onClose,
  successHandler
}: ContractRoyaltyFormModalProps) => {
  return (
    <>
      <Modal size={"lg"} centered onHide={onClose} show={isOpen}>
        <Modal.Header closeButton> Manage Royalty </Modal.Header>
        <Modal.Body >
          <div className="container">
            <ManageRoyaltyForm
              contractAddress={contractAddress}
              royaltyWallet={royaltyWallet}
              royaltyPercent={royaltyPercent}
              successHandler={successHandler}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ContractRoyaltyFormModal;
